import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomInput from 'components/Input/custom-input';
import CustomSelect from 'components/CustomSelect';
import { countryOption } from 'shared/constants/profile-wizard';

type Props = {
  userData: UserInfo;
  changeUserDataFunction: Function;
};

const AnamneseCustomInformation: React.FC<Props> = ({ userData, changeUserDataFunction }) => {
  const { t } = useTranslation();

  const countryChange = (value: any) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      country: value.value,
    }));
  };

  const customerIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        customerId: event.target.value,
      },
    }));
  };

  const telephoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        telephoneNumber: event.target.value,
      },
    }));
  };

  const mobileNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        mobileNumber: event.target.value,
      },
    }));
  };

  const streetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        street: event.target.value,
      },
    }));
  };

  const postCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        postCode: event.target.value,
      },
    }));
  };

  const cityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        city: event.target.value,
      },
    }));
  };

  const birthDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        birthDay: event.target.value,
      },
    }));
  };

  const defaultCountryValue = useMemo(() => {
    if (!userData?.country) {
      return countryOption[0]; // Gibt das erste Element zurück, wenn `userData?.country` nicht existiert
    }
    return countryOption.find((item: any) => item.value === userData.country);
  }, [userData?.country, countryOption]);

  return (
    <div className="flex-grow">
      <div>
        <CustomSelect
          name="gender"
          dropDownOption={countryOption}
          defaultValue={defaultCountryValue}
          onChange={countryChange}
          label="Land / Lebensmitteldatenbank"
          isSearchable
          className="w-full border border-textColor"
        />
        <CustomInput
          name="customerid"
          type="text"
          label="Kundennummer"
          onChange={customerIdChange}
          className="mt-15"
          defaultValue={userData.customProperties?.customerId ?? ''}
          inputClassName="border border-textColor"
        />
        <CustomInput
          name="birthDay"
          type="text"
          label="Geburtsdatum"
          onChange={birthDayChange}
          defaultValue={userData.customProperties?.birthDay ?? ''}
          className="mt-15"
          inputClassName="border border-textColor"
        />
        <CustomInput
          name="telephone"
          type="text"
          label="Telefonnummer"
          onChange={telephoneChange}
          defaultValue={userData.customProperties?.telephoneNumber ?? ''}
          className="mt-15"
          inputClassName="border border-textColor"
        />
        <CustomInput
          name="mobileNumber"
          type="text"
          label="Mobilnummer"
          onChange={mobileNumberChange}
          defaultValue={userData.customProperties?.mobileNumber ?? ''}
          className="mt-15"
          inputClassName="border border-textColor"
        />
        <CustomInput
          name="street"
          type="text"
          label="Straße"
          onChange={streetChange}
          defaultValue={userData.customProperties?.street ?? ''}
          className="mt-15"
          inputClassName="border border-textColor"
        />
        <CustomInput
          name="plz"
          type="text"
          label="Postleitzahl"
          onChange={postCodeChange}
          defaultValue={userData.customProperties?.postCode ?? ''}
          className="mt-15"
          inputClassName="border border-textColor"
        />
        <CustomInput
          name="city"
          type="text"
          label="Stadt"
          onChange={cityChange}
          defaultValue={userData.customProperties?.city ?? ''}
          className="mt-15"
          inputClassName="border border-textColor"
        />
      </div>
    </div>
  );
};

export default AnamneseCustomInformation;
