import React, { useContext } from 'react';
import { XAxis, Tooltip, ResponsiveContainer, LineChart, Line, LabelList } from 'recharts';
import { AuthContext } from 'providers/AuthProvider';
import { defaultAccentColor, defaultBgColor, defaultTextColor } from 'layouts/Theme';
import { InBodyEntry } from '../../../types';
import styles from './styles.module.scss';

interface HistoryProps {
  data: InBodyEntry[];
}

type BarDataItem = { date: string; Gewicht: string; Skelettmuskelmasse: string; Körperfettmasse: string };

const formatDate = (dateStr: string) => {
  const [day, month, year] = dateStr.split('.');
  return `${Number(day) >= 10 || day.startsWith('0') ? day : `0${day}`}.${
    Number(month) >= 10 || month.startsWith('0') ? month : `0${month}`
  }.`; // Return formatted date as DD.MM.
};

const History: React.FC<HistoryProps> = ({ data }) => {
  const { userData, tenant, theme, tenantData } = useContext(AuthContext);

  const barData: BarDataItem[] = data
    .map(entry => {
      return {
        date: formatDate(entry.date),
        Gewicht: entry.inBodyData.Weight.toString(),
        Skelettmuskelmasse: entry.inBodyData.SMM_SkeletalMuscleMass.toString(),
        Körperfettmasse: entry.inBodyData.BFM_BodyFatMass.toString(),
      };
    })
    .slice(0, 4)
    .reverse();

  return (
    <div className="mt-20">
      <div className={styles.chart}>
        <p className="my-auto">Gewicht</p>
        <ResponsiveContainer width="100%" height="100%" aspect={14 / 2.5}>
          <LineChart data={barData} margin={{ top: 10, right: 30, left: 30, bottom: 10 }}>
            <Tooltip wrapperStyle={{ color: '#000', zIndex: 1000 }} />

            <Line
              dataKey="Gewicht"
              stroke={theme?.accentColor ?? defaultAccentColor}
              strokeWidth={2}
              isAnimationActive={false}
              dot={{
                stroke: theme?.textColor ?? defaultTextColor,
                strokeWidth: 2,
                fill: theme?.bgColor ?? defaultBgColor,
                r: 6,
              }}
            >
              <LabelList dataKey="Gewicht" dy={6} position="bottom" className={styles.strokeLabel} />
            </Line>
            <XAxis dataKey="date" hide />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className={styles.chart}>
        <p className="my-auto">Skelettmuskelmasse</p>
        <ResponsiveContainer width="100%" aspect={14 / 2.5}>
          <LineChart data={barData} margin={{ top: 10, right: 30, left: 30, bottom: 10 }}>
            <Tooltip wrapperStyle={{ color: '#000', zIndex: 1000 }} />

            <Line
              dataKey="Skelettmuskelmasse"
              stroke={theme?.accentColor ?? defaultAccentColor}
              strokeWidth={2}
              isAnimationActive={false}
              dot={{
                stroke: theme?.textColor ?? defaultTextColor,
                strokeWidth: 2,
                fill: theme?.bgColor ?? defaultBgColor,
                r: 6,
              }}
            >
              <LabelList dataKey="Skelettmuskelmasse" dy={6} position="bottom" className={styles.strokeLabel} />
            </Line>
            <XAxis dataKey="date" hide />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className={styles.chart}>
        <p className="pt-20 desktop:pt-40">Körperfettmasse</p>
        <ResponsiveContainer width="100%" aspect={14 / 5}>
          <LineChart data={barData} margin={{ top: 30, right: 30, left: 30, bottom: 0 }}>
            <Tooltip wrapperStyle={{ color: '#000', zIndex: 1000 }} />

            <Line
              dataKey="Körperfettmasse"
              stroke={theme?.accentColor ?? defaultAccentColor}
              strokeWidth={2}
              isAnimationActive={false}
              dot={{
                stroke: theme?.textColor ?? defaultTextColor,
                strokeWidth: 2,
                fill: theme?.bgColor ?? defaultBgColor,
                r: 6,
              }}
            >
              <LabelList dataKey="Körperfettmasse" dy={6} position="bottom" className={styles.strokeLabel} />
            </Line>
            <XAxis dataKey="date" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default History;
