import classNames from 'classnames';
import React from 'react';
import Styles from './style.module.scss';

type Props = {
  autoComplete?: string;
  className?: string;
  inputClassName?: string;
  defaultValue?: string;
  label?: string;
  name: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  placeholder?: string;
  required?: boolean;
  suffix?: string;
  type: string;
  value?: string;
  disabled?: boolean;
};

const setBodyClassForMobileSafari = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    document.body.classList.add('keyboard-open');
  }
};

const removeBodyClassForMobileSafari = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    document.body.classList.remove('keyboard-open');
  }
};

const CustomInput = ({
  autoComplete = '',
  className = '',
  inputClassName = '',
  defaultValue,
  label,
  name,
  onChange,
  placeholder = '',
  required = false,
  suffix,
  type,
  value,
  disabled = false,
}: Props) => (
  <div className={className}>
    {label ? (
      <label htmlFor={name} className="block text-sm font-regular text-textColor opacity-50 mb-1">
        {label}
      </label>
    ) : null}
    <div className={Styles.inputWrapper}>
      <input
        autoComplete={autoComplete}
        className={classNames(Styles.inputStyle, { [Styles.suffixInput]: suffix }, inputClassName)}
        defaultValue={defaultValue}
        id={name}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        onFocus={setBodyClassForMobileSafari}
        onBlur={removeBodyClassForMobileSafari}
        tabIndex={0}
        type={type}
        disabled={disabled}
        {...(value ? { value } : {})}
      />
      {suffix && <span className={Styles.suffix}>{suffix}</span>}
    </div>
  </div>
);
export default CustomInput;
