import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChevronRightIcon,
  BellIcon,
  TrashIcon,
  PlusIcon,
  BookOpenIcon,
  UserCircleIcon,
} from '@heroicons/react/outline';
import firebase from 'services/firebase';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toast';
import Headline from 'components/Headline';
import axios from 'axios';
import SwitchSelector from 'react-switch-selector';
import { defaultTextColor, defaultButtonTextColor } from 'layouts/Theme';

import { AuthContext } from 'providers/AuthProvider';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import { MainContext } from 'providers/MainProvider';
import Button from 'components/Button';
import CustomSelect from 'components/CustomSelect';
import ReactLoading from 'react-loading';
import Overlay from 'components/Overlay';
import {
  ROLE_COMPANY_ADMIN,
  ROLE_TRAINER,
  ROLE_MEMBER,
  companyProfileMenu,
  profileMenu,
  companyProfileAdminMenu,
  ROLE_LOCATION_ADMIN,
} from 'shared/constants/global';
import { addAnalyzeToFirebase, generateRandomUid, updateTourToFirebase, changeUserView } from 'shared/functions/global';
import classNames from 'classnames';
import moment from 'moment';
import TransitionContainer from 'components/TransitionContainer';
import EditProfile from './components/editProfile';
import Analyze from './components/analyze';
import Settings from './components/settings';
import Messages from './components/messages';
import HelpAndFAQ from './components/helpFaq';
import Subscription from './components/subscription';
import Legal from './components/legal';
import MyClub from './components/myClub';
import styles from './styles.module.scss';
import ChangePassword from './components/changePassword';

type Props = {};

const Profile: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { userData, tenant, tenantData, theme } = React.useContext(AuthContext);
  const [profileItemIndex, setProfileItemIndex] = useState(-1);
  const [changeViewLoading, setChangeViewLoading] = useState(false);
  const { userMessagesList } = React.useContext(MainContext);
  const authContext = useContext(AuthContext);

  const history = useHistory();

  const tourRef = useRef<HTMLDivElement>(null);

  const analyzeNameRef = useRef<HTMLInputElement>(null);

  const [profileLoaded, setProfileLoaded] = useState(false);

  const [deleteProfilePopupClass, setDeleteProfilePopupClass] = useState('hidden');
  const [addAnalyeItemClass, setAddAnalyeItemClass] = useState('hidden');
  const [legalPopupClass, setLegalPopupClass] = useState('hidden');
  const [legalType, setLegalType] = useState('');
  const [addAnalyeItemValue, setAddAnalyeItemValue] = useState('');
  const [addAnalyeItemPiece, setAddAnalyeItemPiece] = useState('%');
  const [isAnalyze, setIsAnalyze] = useState(false);

  const viewOption = [
    {
      label:
        userData?.role === 4 || userData?.isTrainerUser !== undefined
          ? 'Trainer'
          : userData?.role === 6 || userData?.isLocationAdminUser !== undefined
          ? 'Leiter'
          : 'Admin',
      value: 'admin',
    },
    {
      label: 'Nutzer',
      value: 'user',
    },
  ];

  const db = firebase.firestore();

  const menu =
    userData?.role === ROLE_COMPANY_ADMIN || userData?.role === ROLE_TRAINER || userData?.role === ROLE_LOCATION_ADMIN
      ? companyProfileAdminMenu
      : userData?.role === ROLE_MEMBER
      ? companyProfileMenu
      : profileMenu;
  const contentUserComponents = [
    Messages,
    EditProfile,
    ChangePassword,
    Analyze,
    Subscription,
    Settings,
    HelpAndFAQ,
    Legal,
  ];
  const contentCompanyMemberComponents = [Messages, EditProfile, ChangePassword, Analyze, Settings, MyClub, Legal];
  const contentCompanyAdminComponents = [ChangePassword, Legal];
  const contentComponents =
    userData?.role === ROLE_COMPANY_ADMIN || userData?.role === ROLE_TRAINER
      ? contentCompanyAdminComponents
      : userData?.role === ROLE_MEMBER
      ? contentCompanyMemberComponents
      : contentUserComponents;

  const analyzePieceValues: any = [
    { value: 'percent', label: '%' },
    { value: 'cm', label: 'CM' },
    { value: 'm', label: 'M' },
    { value: 'kg', label: 'KG' },
    { value: 'g', label: 'G' },
    { value: 'mg', label: 'MG' },
    { value: 'ml', label: 'ML' },
    { value: 'l', label: 'L' },
  ];

  const selectIncludeRef = React.useRef() as any;

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
    },
    scrollTo: true,
    classes: 'shepherd-theme-dark',
    useModalOverlay: true,
  };

  function TourButton() {
    const tour = useContext(ShepherdTourContext);

    return (
      <div
        className="hidden"
        ref={tourRef}
        onClick={() => userData?.role === 2 && tour?.start()}
        onKeyDown={() => userData?.role === 2 && tour?.start()}
        aria-hidden="true"
      >
        <div>Test</div>
      </div>
    );
  }

  const changeUserViewExecute = async () => {
    setChangeViewLoading(true);
    if (userData?.role === 5 && (userData?.isAdminUser || userData?.isTrainerUser || userData?.isLocationAdminUser)) {
      const updateUser = await changeUserView(
        userData,
        authContext.user?.uid,
        tenant,
        userData?.isAdminUser ? 3 : userData?.isLocationAdminUser ? 6 : 4
      );

      if (updateUser.status === 'success') {
        authContext.setUserData((prevProfile: UserInfo) => ({
          ...prevProfile,
          role: userData?.isAdminUser ? 3 : userData?.isLocationAdminUser ? 6 : 4,
        }));

        setChangeViewLoading(false);
        history.push('/');
      } else {
        setChangeViewLoading(false);
        toast.error('Es ist leider etwas schiefgelaufen! Bitte versuche es nochmal oder kontaktiere uns!');
      }
    } else if (userData?.isAdminUser || userData?.role === 4 || userData?.role === 6) {
      const updateUser = await changeUserView(userData, authContext.user?.uid, tenant, 5);

      if (updateUser.status === 'success') {
        authContext.setUserData((prevProfile: UserInfo) => ({
          ...prevProfile,
          role: 5,
        }));

        setChangeViewLoading(false);
        history.push('/');
      } else {
        setChangeViewLoading(false);
        toast.error('Es ist leider etwas schiefgelaufen! Bitte versuche es nochmal oder kontaktiere uns!');
      }
    }
  };

  const logOutUser = async () => {
    try {
      await firebase.auth().signOut();
      localStorage.removeItem('currentUserToken');
      window.location.reload();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const deleteUserAccount = async () => {
    try {
      const profile: UserInfo = authContext.userData as UserInfo;

      if (userData?.membership?.type === 'pro' || userData?.membership?.type === 'pro12') {
        if (userData?.membership?.paymentMethod === 'paypal') {
          await axios.post(
            `${process.env.REACT_APP_PAYPAL_SUBSCRIPTION_API ?? ''}/${userData?.membership?.id ?? ''}/cancel`,
            {
              reason: 'Not satisfied with the service',
            },
            {
              auth: {
                username: process.env.REACT_APP_PAYPAL_CLIENTID ?? '',
                password: process.env.REACT_APP_PAYPAL_SECRET ?? '',
              },
            }
          );
        } else {
          await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/cancel-subscription`, {
            subscriptionId: userData?.membership?.id,
          });
        }
      }

      const deleteMailText = `Name:${userData?.fullName}\n Email: ${userData?.email}\n Datum:${moment().format(
        'DD.MM.YYYY'
      )}\n Abo: ${userData?.membership?.type}`;

      // Send delete mail to us
      try {
        await axios({
          url: `${process.env.REACT_APP_API_URL}/sendTextMail`,
          method: 'post',
          data: {
            mailSubject: 'Usersaccount gelöscht',
            mailTo: 'torben@tortija.de',
            mailText: deleteMailText,
          },
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }).then(
          response => {
            console.log(response);
          },
          error => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }

      await db
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .update({
          ...profile,
          toDelete: true,
        });
      toast.success('Dein Profil wurde erfolgreich gelöscht!');
      logOutUser();
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn(error.message);
    }
  };

  const openNewAnalyzePopup = () => {
    setAddAnalyeItemPiece('%');
    if (analyzeNameRef.current) {
      analyzeNameRef.current.value = '';
    }

    setAddAnalyeItemClass('block');
  };

  const updateAnalyzePiece = (value: any) => {
    setAddAnalyeItemPiece(value.label);
  };

  const addNewAnalyzeItem = () => {
    const generateName = generateRandomUid();

    if (addAnalyeItemValue.length > 0) {
      addAnalyzeToFirebase(
        userData,
        authContext.user?.uid,
        true,
        addAnalyeItemValue,
        generateName,
        addAnalyeItemPiece,
        '',
        0,
        '',
        false,
        tenant
      );

      toast.success('Deine Analyse wurde erfolgreich erstellt!');
      setAddAnalyeItemClass('hidden');
    } else {
      toast.error('Bitte gebe einen Namen ein!');
    }
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (tourRef.current && (userData?.cookieBannerType !== 'initial' || userData?.role === 5)) {
        if (userData?.tour?.profile === undefined) {
          tourRef.current.click();
        }
      }

      setProfileLoaded(true);
    }, 1);

    setTimeout(() => {
      document.getElementById('mainContainer')?.scrollTo(0, 0);
    }, 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    /* eslint-disable react/no-danger */
    <>
      <Overlay
        overlayClass={deleteProfilePopupClass}
        setOverlayClass={setDeleteProfilePopupClass}
        headline="Account löschen"
        icon={<TrashIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div>Wollen Sie ihr Profil wirklich löschen?</div>
            <div>Alle gespeicherten Daten werden dabei gelöscht!</div>
          </div>
          {userData?.membership?.stripeCustomerId !== undefined && userData?.membership?.type === 'pro' && (
            <div className="pt-10">
              Sie werden an eine Seite weitergeleitet, wo Sie Ihren Account kündigen können. Nach Ablauf Ihres
              Abonnement-Zeitraums wird Ihr Account automatisch gelöscht!
            </div>
          )}
          <div className="pt-30 flex gap-30 pb-40">
            <div>
              {userData?.membership?.stripeCustomerId !== undefined && userData?.membership?.type === 'pro' ? (
                <Link
                  to={{
                    pathname: `${
                      tenantData?.customDomain !== undefined
                        ? `https://${tenantData.customDomain}`
                        : 'https://business.tortija.de'
                    }/connect-cancel`,
                  }}
                  target="_blank"
                >
                  <Button className="w-full">Account löschen</Button>
                </Link>
              ) : (
                <Button className="w-full" onClick={() => deleteUserAccount()}>
                  Account löschen
                </Button>
              )}
            </div>
            <div>
              <Button className="w-full" onClick={() => setDeleteProfilePopupClass('hidden')}>
                Abbrechen
              </Button>
            </div>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={legalPopupClass}
        setOverlayClass={setLegalPopupClass}
        headline={
          legalType === 'imprint'
            ? 'Impressum'
            : legalType === 'dataprivacy'
            ? 'Datenschutzerklärung'
            : 'Nutzungsbedingungen'
        }
        icon={<BookOpenIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div className="pb-50">
          <div className="pt-30 font-extralight text-base">
            {(tenantData?.legal?.imprintText ||
              tenantData?.legal?.privacyPolicyText ||
              tenantData?.legal?.termsOfUseText) && (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    legalType === 'imprint'
                      ? tenantData?.legal?.imprintText
                        ? tenantData?.legal?.imprintText.replace(/(?:\r\n|\r|\n)/g, '<br />')
                        : ''
                      : legalType === 'dataprivacy'
                      ? tenantData?.legal?.privacyPolicyText
                        ? tenantData?.legal?.privacyPolicyText.replace(/(?:\r\n|\r|\n)/g, '<br />')
                        : ''
                      : tenantData?.legal?.termsOfUseText
                      ? tenantData?.legal?.termsOfUseText.replace(/(?:\r\n|\r|\n)/g, '<br />')
                      : '',
                }}
              />
            )}
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={addAnalyeItemClass}
        setOverlayClass={setAddAnalyeItemClass}
        headline="Neue Analyse erstellen"
        icon={<PlusIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="font-extralight pt-20">
            Bitte gebe hier noch einen Namen für deine Analyse ein und wähle eine Einheit aus.
          </div>
          <div className="flex pt-20">
            <div className="my-auto w-200">Name der Analyse:</div>
            <div className="rounded-md border-solid border border-textColor border-opacity-30 py-2 flex-1">
              <input
                id="analyzeValue"
                name="analyzeValue"
                onFocus={setBodyClassForMobileSafari}
                onBlur={removeBodyClassForMobileSafari}
                ref={analyzeNameRef}
                onChange={e => setAddAnalyeItemValue(e.target.value)}
                type="text"
                className="w-full appearance-none block text-16 font-semibold bg-transparent focus:outline-none bg-opacity-20 text-textColor px-20"
              />
            </div>
          </div>
          <div className="flex pt-20">
            <div className="my-auto w-200">Einheit:</div>
            <div className="flex-1">
              <CustomSelect
                name="analyzePiece"
                dropDownOption={analyzePieceValues}
                value={analyzePieceValues.filter((item: any) => item.label === addAnalyeItemPiece)}
                onChange={updateAnalyzePiece}
                thisRef={selectIncludeRef}
              />
            </div>
          </div>
          <div className="pt-80 pb-40">
            <div>
              <Button className="w-full" onClick={() => addNewAnalyzeItem()}>
                Analyse erstellen
              </Button>
            </div>
          </div>
        </div>
      </Overlay>

      <ShepherdTour
        steps={[
          {
            id: 'intro',
            attachTo: { element: '#item-0', on: 'top' },
            buttons: [
              {
                classes: 'shepherd-button-secondary',
                text: 'Beenden',
                type: 'cancel',
              },
              {
                classes: 'shepherd-button-primary',
                text: 'Weiter',
                type: 'next',
              },
            ],
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            scrollTo: true,
            cancelIcon: {
              enabled: true,
            },
            title: 'Prüfe deine Nachrichten!',
            text: [
              '<p>Wenn dir jemand ein Rezept, Ernährungsplan oder Einkaufsliste über die Teilen Funktion geschickt hat siehst du diese Elemente als Nachrichten hier und kannst sie aufrufen.</p>',
            ],
            when: {
              destroy() {
                updateTourToFirebase(userData, 'profile', authContext.user?.uid!, tenant);
                setTimeout(() => {
                  document.getElementById('mainContainer')?.scrollIntoView();
                }, 1);
              },
            },
          },
          {
            id: 'intro',
            attachTo: { element: '#item-1', on: 'bottom' },
            buttons: [
              {
                classes: 'shepherd-button-secondary',
                text: 'Beenden',
                type: 'cancel',
              },
              {
                classes: 'shepherd-button-primary',
                text: 'Weiter',
                type: 'next',
              },
            ],
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            scrollTo: true,
            cancelIcon: {
              enabled: true,
            },
            title: 'Profil bearbeiten!',
            text: [
              '<p>Hier kannst du deine Profildaten ändern (Persönliche Informationen, Ziele, Ernährungsform und Unverträglichkeiten).</p>',
            ],
          },
          {
            id: 'intro',
            attachTo: { element: '#item-3', on: 'bottom' },
            buttons: [
              {
                classes: 'shepherd-button-secondary',
                text: 'Beenden',
                type: 'cancel',
              },
              {
                classes: 'shepherd-button-primary',
                text: 'Weiter',
                type: 'next',
              },
            ],
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            scrollTo: true,
            cancelIcon: {
              enabled: true,
            },
            title: 'Analyse und Fortschrittskontrolle!',
            text: [
              '<p>Hier kannst du deine Körperwerte (z.B. dein Gewicht) eintragen und den Fortschritt überwachen.</p><p>Du kannst hier zum einen auf von uns vorgefertigte Kategorien zugreifen, aber auch eigene Kategorien anlegen.</p>',
            ],
          },
          {
            id: 'intro',
            attachTo: { element: '#item-4', on: 'bottom' },
            buttons: [
              {
                classes: 'shepherd-button-primary',
                text: 'Zurück',
                type: 'back',
              },
              {
                classes: 'shepherd-button-primary',
                text: 'Beenden',
                type: 'next',
              },
            ],
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            scrollTo: true,
            cancelIcon: {
              enabled: true,
            },
            title: 'Deine Einstellungen!',
            text: [
              '<p>Hier kannst du weitere Einstellungen vornehmen (Wasserbedarf, Kalorienbedarf, Lebensmittel die du nicht magst und deine Cookie Einstellungen).</p>',
            ],
          },
        ]}
        tourOptions={tourOptions}
      >
        <TourButton />
      </ShepherdTour>

      <TransitionContainer isShown={profileLoaded}>
        <div className={styles.header} data-pdf-header>
          <Headline level={1}>{t('Profile')}</Headline>
          {(userData?.isAdminUser || userData?.role === 4 || userData?.isTrainerUser || userData?.role === 6) && (
            <div className="cursor-pointer w-1/2" onClick={() => changeUserViewExecute()} aria-hidden="true">
              <div>
                <div>
                  <div className="font-bold text-14 text-center cursor-pointer pb-10">Ansicht</div>
                  {changeViewLoading ? (
                    <div className="w-full flex justify-center">
                      <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
                    </div>
                  ) : (
                    <SwitchSelector
                      options={viewOption}
                      initialSelectedIndex={0}
                      forcedSelectedIndex={userData?.role === 3 || userData.role === 4 || userData.role === 6 ? 0 : 1}
                      backgroundColor="#3D4045"
                      selectedFontColor={theme?.buttonTextColor ?? defaultButtonTextColor}
                      fontColor={theme?.textColor ?? defaultTextColor}
                      fontSize={14}
                      selectionIndicatorMargin={0}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={isAnalyze ? styles.wrapperAnalyze : styles.wrapper}>
          {!isAnalyze && (
            <div className={classNames(styles.menuWrapper, { hidden: profileItemIndex >= 0 })}>
              <div className={styles.avatar}>
                <div className={styles.photo}>
                  <img src="/logo192.png" width={100} height={100} className="object-cover" alt="User Avatar" />
                </div>
                <div className={styles.name}>{userData?.fullName}</div>
              </div>
              <div className={classNames(styles.menuList)}>
                {menu.map((item, index) => (
                  <div className="relative" id={`item-${index}`} key={`item-${index}`}>
                    <Button
                      buttonStyle={profileItemIndex === index ? 'primary' : 'white'}
                      className="w-full bg-secondaryBgColor"
                      onClick={() => {
                        setProfileItemIndex(index);
                      }}
                      key={index}
                    >
                      <div className="w-full flex justify-between items-center">
                        <div className="flex">
                          <p
                            className={
                              profileItemIndex === index
                                ? 'text-18 font-light text-buttonTextColor'
                                : 'text-18 font-light text-notActiveButtonTextColor'
                            }
                          >
                            {t(item)}
                          </p>

                          {t(item) === 'Nachrichten' && (
                            <div className="pl-10">
                              <span className="relative inline-block">
                                {userMessagesList !== undefined && (
                                  <>
                                    {userMessagesList.filter(messageItem => messageItem.messageRead === false).length >
                                    0 ? (
                                      <>
                                        <BellIcon
                                          width={20}
                                          height={20}
                                          className="text-textColor cursor-pointer animate-swing"
                                        />
                                        <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-10 font-normal leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                          {
                                            userMessagesList.filter(messageItem => messageItem.messageRead === false)
                                              .length
                                          }
                                        </span>
                                      </>
                                    ) : (
                                      <BellIcon
                                        width={20}
                                        height={20}
                                        className={classNames(
                                          'cursor-pointer',
                                          { 'text-accentColor': profileItemIndex !== index },
                                          'text-buttonTextColor'
                                        )}
                                      />
                                    )}
                                  </>
                                )}
                              </span>
                            </div>
                          )}
                        </div>

                        <ChevronRightIcon
                          className={classNames(
                            { 'text-accentColor': profileItemIndex !== index },
                            'text-buttonTextColor'
                          )}
                          height={30}
                          width={30}
                        />
                      </div>
                    </Button>
                  </div>
                ))}
                <div className="flex flex-wrap gap-10 justify-between">
                  <div>
                    <Button className={styles.logoutBtn} onClick={logOutUser}>
                      {t('Log out')}
                    </Button>
                  </div>
                  {userData?.role !== ROLE_COMPANY_ADMIN && (
                    <div>
                      <Button className={styles.logoutBtn} onClick={() => setDeleteProfilePopupClass('block')}>
                        {t('Delete Account')}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className={classNames(styles.content, { 'custom-desktop-scrollbar': !isAnalyze })}>
            {contentComponents.map((Component, index) => (
              <Component
                isShown={profileItemIndex === index}
                key={index}
                setIsAnalyze={menu[index] === 'Analyze' ? setIsAnalyze : undefined}
                setAddNewItemFunction={menu[index] === 'Analyze' ? openNewAnalyzePopup : undefined}
                title={menu[index]}
                goBack={() => {
                  setProfileItemIndex(-1);
                }}
                setLegalPopupClass={menu[index] === 'Legal' ? setLegalPopupClass : undefined}
                setLegalType={menu[index] === 'Legal' ? setLegalType : undefined}
              />
            ))}
          </div>
        </div>
      </TransitionContainer>
    </>
  );
};

export default Profile;
