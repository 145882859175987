import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import firebase from 'services/firebase';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toast';
import Icon from 'components/Icon';
import { AuthContext } from 'providers/AuthProvider';
import LogoImg from 'assets/img/svg/logo.svg';
import { getMobileOperatingSystem } from 'shared/functions/global';
import Container from 'components/Container';

import styles from './styles.module.scss';

const PurchaseConnectStatus = ({ clientSecret }: { clientSecret: string | null }) => {
  const userDomain = window.location.hostname;
  const db = firebase.firestore();
  const elements = useElements();
  const history = useHistory();
  const stripe = useStripe();
  const [status, setStatus] = useState('');
  const [subscriptionId, setSubscribtionId] = useState('');
  const [paymentIntendId, setPaymentIntendId] = useState('');
  const { theme, setTheme, tenant, userData } = useContext(AuthContext);
  const authContext = useContext(AuthContext);

  const logo = theme?.logo ?? LogoImg;

  const handleOnSuccess = async () => {
    if (!stripe || !elements) {
      return;
    }

    if (sessionStorage.getItem('tenant') !== undefined) {
      if (clientSecret?.startsWith('seti_')) {
        try {
          await axios({
            url: `${process.env.REACT_APP_API_URL}/updateUserDefaultPaymentMethod`,
            method: 'post',
            data: {
              stripeAccountId: sessionStorage.getItem('accountId'),
              customerId: sessionStorage.getItem('customerId'),
            },
            headers: {
              'content-type': 'application/json',
              Accept: 'application/json',
            },
          }).then(
            response => {
              console.log(response);
            },
            error => {
              console.log(error);
            }
          );
        } catch (error) {
          console.log('error: ', error);
        }
      }

      if (sessionStorage.getItem('companyName') === 'stripeConnectTrial') {
        try {
          const newObject = {
            ...userData,
            membership: {
              id: subscriptionId,
              activated: true,
              payerId: paymentIntendId,
              stripeCustomerId: sessionStorage.getItem('customerId'),
              nextDate: moment().unix(),
              paymentMethod: 'stripeConnect',
              type: 'pro',
            },
          };

          console.log(sessionStorage.getItem('tempUid'));

          if (sessionStorage.getItem('tempUid') !== null) {
            await firebase
              .firestore()
              .collection(`tenants/${tenant}/users`)
              .doc(sessionStorage.getItem('tempUid')!)
              .update(newObject);
          } else {
            await firebase
              .firestore()
              .collection(`tenants/${tenant}/users`)
              .doc(authContext.user?.uid)
              .update(newObject);
          }

          sessionStorage.removeItem('name');
          sessionStorage.removeItem('accountId');
          sessionStorage.removeItem('userMail');
          sessionStorage.removeItem('userPw');
          sessionStorage.removeItem('companyName');
          sessionStorage.removeItem('adminMail');
          sessionStorage.removeItem('invitationLink');
          sessionStorage.removeItem('mailSubject');
          sessionStorage.removeItem('mailName');
          sessionStorage.removeItem('mailFrom');
          sessionStorage.removeItem('mailText');
          sessionStorage.removeItem('tempUid');

          history.push('/');
        } catch (error) {
          sessionStorage.removeItem('name');
          sessionStorage.removeItem('accountId');
          sessionStorage.removeItem('userMail');
          sessionStorage.removeItem('userPw');
          sessionStorage.removeItem('companyName');
          sessionStorage.removeItem('adminMail');
          sessionStorage.removeItem('invitationLink');
          sessionStorage.removeItem('mailSubject');
          sessionStorage.removeItem('mailName');
          sessionStorage.removeItem('mailFrom');
          sessionStorage.removeItem('mailText');
          sessionStorage.removeItem('tempUid');

          history.push(`/company-payment/${tenant}`);
          console.log('error: ', error);
        }
      } else {
        try {
          await firebase
            .auth()
            .createUserWithEmailAndPassword(sessionStorage.getItem('userMail')!, sessionStorage.getItem('userPw')!)
            .then(async userCredential => {
              const collection = `tenants/${sessionStorage.getItem('tenant')!}/users`;

              await userCredential.user?.updateProfile({
                displayName: sessionStorage.getItem('tenant'),
              });

              await db
                .collection(collection)
                .doc(userCredential.user?.uid)
                .set({
                  email: sessionStorage.getItem('userMail'),
                  accountExpired: firebase.firestore.FieldValue.delete(),
                  fullName: sessionStorage.getItem('name'),
                  gymID: '',
                  role: 5,
                  userAgent: getMobileOperatingSystem(),
                  profileComplete: false,
                  cookieBannerType: 'initial',
                  created: firebase.firestore.FieldValue.serverTimestamp(),
                  withCouponCode: false,
                  membership: {
                    id: subscriptionId,
                    activated: true,
                    payerId: paymentIntendId,
                    stripeCustomerId: sessionStorage.getItem('customerId'),
                    nextDate: moment().unix(),
                    paymentMethod: 'stripeConnect',
                    type: 'pro',
                  },
                  uid: userCredential.user?.uid,
                });
            })
            .catch(error => {
              console.error(error.message);
              toast.error('5');
              toast.error(error.message);
            });

          // Send Mail to user
          let invitationMailText = `Hallo ${sessionStorage.getItem(
            'name'
          )!},\n\nvielen Dank für deine Registrieung bei der Ernährungssoftware von ${sessionStorage.getItem(
            'companyName'
          )!}.\n\nDeine Zahlung war erfolgreich und du kannst jetzt starten.\n\nBitte klicke auf den folgenden Link, um weitere Informationen zu erhalten und die Links zum Download der App zu bekommen:\n${
            sessionStorage.getItem('invitationLink') !== undefined
              ? sessionStorage.getItem('invitationLink')!
              : 'https://whitelabel.tortija.de/einladung/'
          }\n\nViel Spaß mit unserer Ernährungssoftware!\n\nDein Team von ${sessionStorage.getItem('companyName')!}`;

          if (sessionStorage.getItem('mailText') !== undefined && sessionStorage.getItem('mailText')!.length > 0) {
            invitationMailText = `Hallo ${sessionStorage.getItem('name')!},\n\n${sessionStorage.getItem('mailText')!}`;
          }

          const adminMailText = `Neue Registrierung über Tortija Billing:\n\nName: ${sessionStorage.getItem(
            'name'
          )!}\n\nEmail: ${sessionStorage.getItem('userMail')!}`;

          axios({
            url: `${process.env.REACT_APP_API_URL}/sendTextMail`,
            method: 'post',
            data: {
              mailSubject:
                sessionStorage.getItem('mailSubject') !== undefined && sessionStorage.getItem('mailSubject')!.length > 0
                  ? sessionStorage.getItem('mailSubject')
                  : `${sessionStorage.getItem('companyName')!} Registrierung`,
              mailTo: sessionStorage.getItem('userMail'),
              mailText: invitationMailText,
              mailFrom:
                sessionStorage.getItem('mailFrom') !== undefined && sessionStorage.getItem('mailFrom')!.length > 0
                  ? sessionStorage.getItem('mailFrom')
                  : 'info@tortija.de',
              mailName:
                sessionStorage.getItem('mailName') !== undefined && sessionStorage.getItem('mailName')!.length > 0
                  ? sessionStorage.getItem('mailName')
                  : '',
            },
            headers: {
              'content-type': 'application/json',
              Accept: 'application/json',
            },
          }).then(
            response => {
              console.log(response);
            },
            error => {
              console.log(error);
            }
          );

          if (sessionStorage.getItem('adminMail') !== undefined) {
            axios({
              url: `${process.env.REACT_APP_API_URL}/sendCustomTextMail`,
              method: 'post',
              data: {
                mailSubject: `Tortija Billing: Neue Registrierung`,
                mailTo: sessionStorage.getItem('adminMail'),
                mailText: adminMailText,
                mailFrom: 'info@tortija.de',
              },
              headers: {
                'content-type': 'application/json',
                Accept: 'application/json',
              },
            }).then(
              response => {
                console.log(response);
              },
              error => {
                console.log(error);
              }
            );
          }

          sessionStorage.removeItem('name');
          sessionStorage.removeItem('accountId');
          sessionStorage.removeItem('userMail');
          sessionStorage.removeItem('userPw');
          sessionStorage.removeItem('companyName');
          sessionStorage.removeItem('adminMail');
          sessionStorage.removeItem('invitationLink');
          sessionStorage.removeItem('mailSubject');
          sessionStorage.removeItem('mailName');
          sessionStorage.removeItem('mailFrom');
          sessionStorage.removeItem('mailText');
          sessionStorage.removeItem('tempUid');

          history.push('/store-landingpage');
        } catch (error) {
          sessionStorage.removeItem('name');
          sessionStorage.removeItem('accountId');
          sessionStorage.removeItem('userMail');
          sessionStorage.removeItem('userPw');
          sessionStorage.removeItem('companyName');
          sessionStorage.removeItem('adminMail');
          sessionStorage.removeItem('invitationLink');
          sessionStorage.removeItem('mailSubject');
          sessionStorage.removeItem('mailName');
          sessionStorage.removeItem('mailFrom');
          sessionStorage.removeItem('mailText');
          sessionStorage.removeItem('tempUid');

          history.push('/store-landingpage');
          console.log('error: ', error);
        }
      }
    }
  };

  useEffect(() => {
    if (status === 'succeeded' && subscriptionId && paymentIntendId) {
      if (sessionStorage.getItem('tenant') !== undefined) {
        toast.success('Deine Zahlung wurde erfolgreich durchgeführt. Vielen Dank!');
        handleOnSuccess();
      } else {
        toast.error('Es ist leider ein Fehler aufgetreten. Bitte versuche es später erneut!');
      }
    }
    if (status === 'processing' && subscriptionId && paymentIntendId) {
      if (sessionStorage.getItem('tenant') !== undefined) {
        toast.success('Deine Zahlung wurde erfolgreich durchgeführt. Vielen Dank!');
        handleOnSuccess();
      } else {
        toast.error('Es ist leider ein Fehler aufgetreten. Bitte versuche es später erneut!');
      }
    }
    if (status === 'requires_payment_method') {
      toast.error('Deine Zahlung ist fehlgeschlagen. Bitte versuche es mit einer anderen Zahlungsmethode.');

      if (sessionStorage.getItem('companyName') === 'stripeConnectTrial') {
        history.push(`/company-payment/${tenant}`);
      } else {
        history.push(`/company-register/${sessionStorage.getItem('tenant')!}`);
      }
    }
  }, [status, subscriptionId]);

  useEffect(() => {
    // search for customDomain inside tenants which is equal to userDomain
    firebase
      .firestore()
      .collection('customDomain-infos')
      .where('customDomain', '==', userDomain)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          const tenantInfo = querySnapshot.docs[0].data();
          setTheme(tenantInfo?.theme as TenantTheme);
        }
      });
  }, []);

  if (stripe) {
    if (clientSecret?.startsWith('pi_')) {
      // Handling for PaymentIntent
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        if (paymentIntent?.id !== undefined) {
          setPaymentIntendId(paymentIntent.id);
        }
        setStatus(paymentIntent?.status!);
        setSubscribtionId(sessionStorage.getItem('subscriptionId')!);
      });
    } else if (clientSecret?.startsWith('seti_')) {
      // Handling for SetupIntent
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        if (setupIntent?.id !== undefined) {
          setPaymentIntendId(setupIntent.id);
        }
        setStatus(setupIntent?.status!);
        setSubscribtionId(sessionStorage.getItem('subscriptionId')!);
      });
    }
  }

  return (
    <Container>
      <div className="fixed h-screen w-screen left-0 top-0 bg-bgColor z-50 flex flex-col items-center justify-center">
        <img width={200} src={logo} alt="" />
        <div className="font-bold pt-30">
          <div className="text-center">Deine Zahlung wird bearbeitet...</div>
          <div className="pt-10 text-center">Dieser Vorgang kann unter Umständen auch etwas länger dauern.</div>
          <div className="pt-10 text-center">Bitte schließe dieses Fenster nicht. Du wirst gleich weitergeleitet.</div>
        </div>
      </div>
    </Container>
  );
};

export default PurchaseConnectStatus;
