import React from 'react';
import moment from 'moment';
import { PaperClipIcon } from '@heroicons/react/outline';
import { StarIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  userData?: any;
};

const CoachingDashboardBodyData: React.FC<Props> = ({ userData }) => {
  return (
    <div className="pt-30">
      <div>Körperdaten</div>
    </div>
  );
};

export default CoachingDashboardBodyData;
