import { AuthContext } from 'providers/AuthProvider';
import React, { useContext } from 'react';

type Props = {
  children: React.ReactNode;
};

export const darkenColor = (color: string | undefined, percent: number) => {
  if (!color) {
    return undefined;
  }
  // Remove the hash from the color code if it's present
  const newColor = color.startsWith('#') ? color.slice(1) : color;

  // Convert the color to RGB
  let r = parseInt(newColor.slice(0, 2), 16);
  let g = parseInt(newColor.slice(2, 4), 16);
  let b = parseInt(newColor.slice(4, 6), 16);

  // Darken the color by the provided percentage
  r = Math.floor(r * (1 - percent / 100));
  g = Math.floor(g * (1 - percent / 100));
  b = Math.floor(b * (1 - percent / 100));

  // Convert the RGB color back to hexadecimal and return it
  const newR = r.toString(16).padStart(2, '0');
  const newG = g.toString(16).padStart(2, '0');
  const newB = b.toString(16).padStart(2, '0');

  return `#${newR}${newG}${newB}`;
};

export const lightenColor = (color: string | undefined, percent: number) => {
  if (!color) {
    return undefined;
  }
  // Remove the hash from the color code if it's present
  const newColor = color.startsWith('#') ? color.slice(1) : color;

  // Convert the color to RGB
  let r = parseInt(newColor.slice(0, 2), 16);
  let g = parseInt(newColor.slice(2, 4), 16);
  let b = parseInt(newColor.slice(4, 6), 16);

  // Lighten the color by the provided percentage
  r = Math.floor(r + (255 - r) * (percent / 100));
  g = Math.floor(g + (255 - g) * (percent / 100));
  b = Math.floor(b + (255 - b) * (percent / 100));

  // Convert the RGB color back to hexadecimal and return it
  const newR = r.toString(16).padStart(2, '0');
  const newG = g.toString(16).padStart(2, '0');
  const newB = b.toString(16).padStart(2, '0');

  return `#${newR}${newG}${newB}`;
};

export const isLight = (hex: string | undefined) => {
  if (!hex) {
    return undefined;
  }
  // Convert hex to RGB first
  let r: any = '';
  let g: any = '';
  let b: any = '';
  if (hex.length === 4) {
    // 3 digits
    r = `0x${hex[1]}${hex[1]}`;
    g = `0x${hex[2]}${hex[2]}`;
    b = `0x${hex[3]}${hex[3]}`;
  } else if (hex.length === 7) {
    // 6 digits
    r = `0x${hex[1]}${hex[2]}`;
    g = `0x${hex[3]}${hex[4]}`;
    b = `0x${hex[5]}${hex[6]}`;
  }
  // Then to luma
  const luma = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // A luma value over 0.5 is considered light
  return luma > 0.5;
};

export const defaultTextColor = '#FFFFFF';
export const defaultBgColor = '#1B1D1F';
// #84502B
export const defaultAccentColor = '#C97132';
export const defaultAccentColorDark = '#DAA072';
export const defaultButtonTextColor = '#FFFFFF';
export const defaultNotActiveButtonTextColor = '#FFFFFF';
export const defaultLightGray = '#2a2c2e';
export const defaultLightGrayDarker = '#404243';
export const defaultWhiteGray = '#b7bcc4';
export const defaultLightGrayAlternative = '#404243';
export const defaultBlackSemiDark = '#707070';
export const defaultLightDarkGray = '#2a2c2e';
export const defaultLightRed = '#fc0303';
export const defaultLightGreen = '#5efc03';
export const defaultCalories = '#01A2E0';
export const defaultCarbohydrates = '#DE4C39';
export const defaultProtein = '#8DC703';
export const defaultFat = '#FBA500';
export const defaultSecondaryBgColor = '#2A2C2E';

const Theme: React.FC<Props> = ({ children }) => {
  const { theme } = useContext(AuthContext);

  document.documentElement.style.setProperty('--textColor', theme?.textColor ?? defaultTextColor);
  document.documentElement.style.setProperty('--bgColor', theme?.bgColor ?? defaultBgColor);
  document.documentElement.style.setProperty('--accentColor', theme?.accentColor ?? defaultAccentColor);
  document.documentElement.style.setProperty(
    '--accentColorDark',
    theme?.accentColor && theme?.accentColor !== '#C97132'
      ? !isLight(theme?.accentColor)
        ? lightenColor(theme?.accentColor, 40) ?? defaultAccentColorDark
        : darkenColor(theme?.accentColor, 40) ?? defaultAccentColorDark
      : defaultAccentColorDark
  );
  document.documentElement.style.setProperty(
    '--secondaryBgColor',
    theme?.secondaryBgColor
      ? theme?.secondaryBgColor
      : theme?.bgColor
      ? !isLight(theme?.bgColor)
        ? lightenColor(theme?.bgColor, 10) ?? defaultSecondaryBgColor
        : darkenColor(theme?.bgColor, 10) ?? defaultSecondaryBgColor
      : defaultSecondaryBgColor
  );
  document.documentElement.style.setProperty('--buttonTextColor', theme?.buttonTextColor ?? defaultButtonTextColor);
  document.documentElement.style.setProperty(
    '--buttonNotActiveTextColor',
    theme?.notActiveButtonTextColor ?? defaultNotActiveButtonTextColor
  );
  document.documentElement.style.setProperty(
    '--lightGray',
    theme?.bgColor
      ? !isLight(theme?.bgColor)
        ? lightenColor(theme?.bgColor, 10) ?? defaultLightGray
        : darkenColor(theme?.bgColor, 10) ?? defaultLightGray
      : defaultLightGray
  );
  document.documentElement.style.setProperty(
    '--lightGrayDarker',
    theme?.bgColor
      ? !isLight(theme?.bgColor)
        ? lightenColor(theme?.bgColor, 25) ?? defaultLightGrayDarker
        : darkenColor(theme?.bgColor, 25) ?? defaultLightGrayDarker
      : defaultLightGrayDarker
  );
  document.documentElement.style.setProperty('--whiteGray', defaultWhiteGray);
  document.documentElement.style.setProperty('--lightGrayAlternative', defaultLightGrayAlternative);
  document.documentElement.style.setProperty('--blackSemiDark', defaultBlackSemiDark);
  document.documentElement.style.setProperty(
    '--lightDarkGray',
    theme?.bgColor
      ? !isLight(theme?.bgColor)
        ? lightenColor(theme?.bgColor, 30) ?? defaultLightDarkGray
        : darkenColor(theme?.bgColor, 30) ?? defaultLightDarkGray
      : defaultLightDarkGray
  );
  document.documentElement.style.setProperty('--lightRed', defaultLightRed);
  document.documentElement.style.setProperty('--lightGreen', defaultLightGreen);
  document.documentElement.style.setProperty('--calories', defaultCalories);
  document.documentElement.style.setProperty('--carbohydrates', defaultCarbohydrates);
  document.documentElement.style.setProperty('--protein', defaultProtein);
  document.documentElement.style.setProperty('--fat', defaultFat);

  return <div>{children}</div>;
};

export default Theme;
