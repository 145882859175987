import React, { useMemo, useState, useEffect, useContext } from 'react';
import CustomSelect from 'components/CustomSelect';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import firebase from 'services/firebase';
import { AuthContext } from 'providers/AuthProvider';
import { toast } from 'react-toast';
import Overlay from 'components/Overlay';
import {
  intolerancesOption,
  eatingHabitsOption,
  yourGoal,
  physicalStrainOption,
} from 'shared/constants/profile-wizard';
import { XIcon, CheckIcon, PlusIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { PlusCircleIcon } from '@heroicons/react/solid';
import AnamnesePersonalInformation from './components/AnamnesePersonalInformation';
import AnamneseCustomInformation from './components/AnamneseCustomInformation';
import styles from './styles.module.scss';

type Props = {
  userData: UserInfo;
  userId: string;
};

const CoachingDashboardAnamnese: React.FC<Props> = ({ userData, userId }) => {
  const { t } = useTranslation();
  const { tenant } = useContext(AuthContext);

  const [userSaveLoaded, setUserSaveLoaded] = useState(false);
  const [changedUserData, setChangedUserData] = useState<UserInfo>({} as UserInfo);

  const [currentIncompatibilitiesAddList, setCurrentIncompatibilitiesAddList] = useState<any>([]);

  const [currentUpdateObject, setCurrentUpdateObject] = useState<any>({});

  const [currentEatingHabitsAddList, setCurrentEatingHabitsAddList] = useState<any>([]);

  const selectIncompatibilitiesRef = React.useRef() as any;

  const selectEatingHabitsRef = React.useRef() as any;

  const [incompatibilitiesSearchVisible, setIncompatibilitiesSearchVisible] = useState(false);

  const [changeWeightPopupClass, setChangeWeightPopupClass] = useState('hidden');

  const [eatingHabitsSearchVisible, setEatingHabitsSearchVisible] = useState(false);

  const formOfNutritionOptions: any = [
    { value: 'Flexitarian', label: t('Flexitarian') },
    { value: 'Vegan', label: t('Vegan') },
    { value: 'Vegetarian', label: t('Vegetarian') },
    { value: 'Ketogen', label: t('Ketogen') },
  ];

  const goalOptions: any = [
    { value: 'Decrease', label: t('Decrease') },
    { value: 'Gain', label: t('Gain') },
    { value: 'Maintain', label: t('Maintain') },
  ];

  const filteredIntolerances = useMemo(() => {
    const incompatibilities = changedUserData?.incompatibilities || [];

    // Filter und transformiere die Intoleranzen
    const transformedIntolerances = intolerancesOption
      .filter(intolerance => !incompatibilities.includes(intolerance))
      .map(intolerance => ({
        value: intolerance,
        label: t(intolerance),
      }));

    return transformedIntolerances;
  }, [changedUserData?.incompatibilities]);

  const filteredEatingHabits = useMemo(() => {
    const eatingHabits = changedUserData?.eatingHabits || [];

    // Filter und transformiere die Intoleranzen
    const transformedEatingHabits = eatingHabitsOption
      .filter(eatingHabit => !eatingHabits.includes(eatingHabit))
      .map(eatingHabit => ({
        value: eatingHabit,
        label: t(eatingHabit),
      }));

    return transformedEatingHabits;
  }, [changedUserData?.eatingHabits]);

  const defaultFormOfNutritionValue = useMemo(
    () => formOfNutritionOptions.filter((item: any) => item.value === userData?.formOfNutrition),
    [userData?.formOfNutrition]
  );

  const defaultGoalValue = useMemo(
    () => goalOptions.filter((item: any) => item.value === userData?.goal),
    [userData?.goal]
  );

  const formOfNutritionChange = (value: any) => {
    setChangedUserData((prevProfile: UserInfo) => ({ ...prevProfile, formOfNutrition: value.value }));
  };

  const goalChange = (value: any) => {
    setChangedUserData((prevProfile: UserInfo) => ({ ...prevProfile, goal: value.value }));
  };

  const changeIncompatibilitiesGroup = (value: any) => {
    const transformedValues = value.map((item: any) => item.value);

    setCurrentIncompatibilitiesAddList(transformedValues);
  };

  const changeEatingHabitsGroup = (value: any) => {
    const transformedValues = value.map((item: any) => item.value);

    setCurrentEatingHabitsAddList(transformedValues);
  };

  const saveEatingHabits = () => {
    const newColumns = {
      ...changedUserData,
      eatingHabits: [...(changedUserData.eatingHabits || []), ...currentEatingHabitsAddList],
    };

    if (currentEatingHabitsAddList.length > 0) {
      saveUserData(newColumns);
    }

    setCurrentEatingHabitsAddList([]);

    setEatingHabitsSearchVisible(false);
  };

  const resetEatingHabits = () => {
    const newColumns = {
      ...changedUserData,
      eatingHabits: [...(changedUserData.eatingHabits as any)],
    };

    setCurrentEatingHabitsAddList([]);

    setChangedUserData(newColumns);

    setEatingHabitsSearchVisible(false);
  };

  const deleteEatingHabitsList = (eatingHabitsName: string) => {
    let thisObject = [] as any;

    if (changedUserData.eatingHabits !== undefined) {
      thisObject = [...changedUserData.eatingHabits.filter((st: any) => !st.includes(eatingHabitsName))];
    }

    const newColumns = {
      ...changedUserData,
      eatingHabits: [...thisObject],
    };

    saveUserData(newColumns);
  };

  const saveIncompatibilities = () => {
    const newColumns = {
      ...changedUserData,
      incompatibilities: [...(changedUserData.incompatibilities || []), ...currentIncompatibilitiesAddList],
    };

    if (currentIncompatibilitiesAddList.length > 0) {
      saveUserData(newColumns);
    }
  };

  const resetIncompatibilities = () => {
    const newColumns = {
      ...changedUserData,
      incompatibilities: [...(changedUserData.incompatibilities as any)],
    };

    setCurrentIncompatibilitiesAddList([]);

    setChangedUserData(newColumns);

    setIncompatibilitiesSearchVisible(false);
  };

  const deleteIncompatibilitiesList = (incompatibilityName: string) => {
    let thisObject = [] as any;

    if (changedUserData.incompatibilities !== undefined) {
      thisObject = [...changedUserData.incompatibilities.filter((st: any) => !st.includes(incompatibilityName))];
    }

    const newColumns = {
      ...changedUserData,
      incompatibilities: [...thisObject],
    };

    saveUserData(newColumns);
  };

  const checkWeightChange = (changeType: string) => {
    let newObject = {} as any;

    if (changeType === 'withCalculate') {
      let thisCalculatedCarbohydrates = 0;
      let thisCalculatedProtein = 0;
      let thisCalculatedFat = 0;
      const thisCarbohydratesPercent = changedUserData?.custom_carbohydrates_percent ?? '50';
      const thisProteinPercent = changedUserData?.custom_protein_percent ?? '25';
      const thisFatPercent = changedUserData?.custom_fat_percent ?? '25';
      const dCaloriesList = [-500, 250, 0];

      const age = parseFloat(changedUserData?.age ?? '') ?? 0;
      const height = parseFloat(changedUserData?.bodySize ?? '') ?? 0;
      const weight = parseFloat(changedUserData?.bodyWeight ?? '') ?? 0;
      let calories = 0;
      if (changedUserData?.gender === 'man') {
        calories = 10 * weight + 6.25 * height - 5 * age + 5;
      } else if (changedUserData?.gender === 'woman') {
        calories = 10 * weight + 6.25 * height - 5 * age - 161;
      }

      const pal =
        physicalStrainOption.find((item: any) => item.value === changedUserData?.physicalStrainChange)?.pal ?? 1;
      calories *= pal;

      const dCalories =
        changedUserData?.goal !== undefined
          ? dCaloriesList[yourGoal.findIndex(goal => goal === changedUserData.goal)] ?? 0
          : 0;
      calories += dCalories;

      if (
        changedUserData?.custom_nutrition_type === undefined ||
        (changedUserData?.custom_nutrition_type !== undefined && changedUserData?.custom_nutrition_type === 'standard')
      ) {
        thisCalculatedCarbohydrates = Math.round((calories * 50) / 100 / 4.1);
        thisCalculatedProtein = Math.round((calories * 25) / 100 / 4.1);
        thisCalculatedFat = Math.round((calories * 25) / 100 / 9.3);
      } else if (
        changedUserData?.custom_nutrition_type !== undefined &&
        changedUserData?.custom_nutrition_type === 'user'
      ) {
        thisCalculatedCarbohydrates = Math.round((calories * parseFloat(thisCarbohydratesPercent)) / 100 / 4.1);
        thisCalculatedProtein = Math.round((calories * parseFloat(thisProteinPercent)) / 100 / 4.1);
        thisCalculatedFat = Math.round((calories * parseFloat(thisFatPercent)) / 100 / 9.3);
      } else {
        thisCalculatedCarbohydrates = Math.round((calories * parseFloat(thisCarbohydratesPercent)) / 100 / 4.1);
        thisCalculatedProtein = Math.round((calories * parseFloat(thisProteinPercent)) / 100 / 4.1);
        thisCalculatedFat = Math.round((calories * parseFloat(thisFatPercent)) / 100 / 9.3);
      }

      newObject = {
        ...changedUserData,
        calories: calories.toString(),
        carbohydrates_total: thisCalculatedCarbohydrates,
        protein_total: thisCalculatedProtein,
        fat_total: thisCalculatedFat,
      };
    } else {
      console.log(currentUpdateObject);
      newObject = currentUpdateObject;
    }

    const weightName = moment().locale('de').format('DD. MMM YYYY');

    const newEntry = {
      name: weightName,
      value: changedUserData.bodyWeight,
      date: moment().format('YYYY-MM-DD'),
    };

    // Check if weight exists
    let checkIfWeightExists: boolean = false;

    if (userData?.analyze !== undefined) {
      const getWeightObject = userData?.analyze.items?.filter(item => item.name === 'weight');

      if (getWeightObject.length > 0) {
        checkIfWeightExists = true;
      }
    }

    if (checkIfWeightExists === true) {
      const newColumns = {
        ...newObject,
        analyze: {
          ...userData.analyze,
          items: userData.analyze?.items?.map((entries: any) => {
            if (entries.name !== 'weight') return entries;
            return {
              ...entries,
              itemValues: [...entries.itemValues.filter((item: any) => item.date !== newEntry.date), newEntry],
            };
          }),
        },
      };

      newObject = newColumns;
    } else if (userData.analyze !== undefined && userData.analyze.items.length > 0 && checkIfWeightExists) {
      const newColumns = {
        ...newObject,
        analyze: {
          ...userData.analyze,
          items: userData.analyze?.items?.map((entries: any) => {
            if (entries.name !== 'weight') return entries;
            return {
              ...entries,
              itemValues: [...entries.itemValues.filter((item: any) => item.date !== newEntry.date), newEntry],
            };
          }),
        },
      };

      newObject = newColumns;
    } else if (userData.analyze !== undefined && userData.analyze.items.length > 0 && !checkIfWeightExists) {
      const newColumns = {
        ...newObject,
        analyze: {
          ...userData.analyze,
          items: [
            ...userData.analyze?.items,
            {
              isCustom: false,
              label: 'Gewicht',
              name: 'weight',
              piece: 'KG',
              itemValues: [
                {
                  name: weightName,
                  value: parseFloat(changedUserData.bodyWeight),
                  date: moment().format('YYYY-MM-DD'),
                },
              ],
            },
          ],
        },
      };

      newObject = newColumns;
    } else {
      const newColumns = {
        ...newObject,
        analyze: {
          items: [
            {
              isCustom: false,
              label: 'Gewicht',
              name: 'weight',
              piece: 'KG',
              itemValues: [
                {
                  name: weightName,
                  value: parseFloat(changedUserData.bodyWeight),
                  date: moment().format('YYYY-MM-DD'),
                },
              ],
            },
          ],
        },
      };

      newObject = newColumns;
    }

    saveUserDataExecute(newObject);
  };

  const saveUserDataExecute = async (updateObject?: any) => {
    setCurrentUpdateObject({});
    setUserSaveLoaded(true);
    if (userId.length === 0) {
      toast.error('Es ist leider etwas schief gelaufen. Bitte versuchen Sie es erneut!');
      setChangeWeightPopupClass('hidden');
      setUserSaveLoaded(false);
      return;
    }

    try {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(userId)
        .update(updateObject || { ...changedUserData });

      setUserSaveLoaded(false);

      if (updateObject !== undefined) {
        setChangedUserData(updateObject);
      }

      setCurrentEatingHabitsAddList([]);
      setEatingHabitsSearchVisible(false);

      setCurrentIncompatibilitiesAddList([]);
      setIncompatibilitiesSearchVisible(false);

      setChangeWeightPopupClass('hidden');

      toast.success('Das Profil wurde erfolgreich geändert!');
    } catch (e) {
      setUserSaveLoaded(false);
      setChangeWeightPopupClass('hidden');
      toast.error('Es ist leider etwas schief gelaufen. Bitte versuchen Sie es erneut!');
    }
  };

  const saveUserData = (updateObject?: any) => {
    if (userData?.bodyWeight !== changedUserData?.bodyWeight) {
      if (updateObject !== undefined) {
        setCurrentUpdateObject(updateObject);
      } else {
        setCurrentUpdateObject(changedUserData);
      }

      setChangeWeightPopupClass('block');
    } else if (updateObject !== undefined) {
      saveUserDataExecute(updateObject);
    } else {
      saveUserDataExecute();
    }
  };

  useEffect(() => {
    setChangedUserData(userData);
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line

  return (
    <div>
      <Overlay
        setOverlayClass={setChangeWeightPopupClass}
        overlayClass={changeWeightPopupClass}
        fullSize
        topPadding
        headline="Gewichtsänderung: Werte anpassen"
        icon={<PlusIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div className="pt-30 font-extralight text-base">
          <div>Sie haben das Gewicht des Kunden verändert.</div>
          <div className="pt-10">
            Wollen Sie den Kalorienbedarf inkl. Neu-Berechnung der Makro-Werte (Eiweiß, Kohlenhydrate, Fett) anpassen
            oder soll nur das Profil gespeichert werden?
          </div>
          <div className="pt-30 flex gap-30 pb-15">
            <Button
              className="w-full"
              isPending={userSaveLoaded}
              disabled={userSaveLoaded}
              onClick={() => checkWeightChange('withCalculate')}
            >
              Speichern mit Neu-Berechnung
            </Button>
          </div>
          <div className="pb-40">
            <Button
              className="w-full"
              isPending={userSaveLoaded}
              disabled={userSaveLoaded}
              onClick={() => checkWeightChange('withoutCalculate')}
            >
              Profil ohne Berechnung speichern
            </Button>
          </div>
        </div>
      </Overlay>

      <div className="pt-40">
        <div className="flex gap-40 flex-wrap">
          <div className="flex-1 flex flex-col">
            <div className="font-bold text-25">Persönliche Informationen</div>
            <div className="font-extralight text-13">
              Diese Daten werden vom Nutzer bei der Registrierung oder beim Ändern des persönlichen Profils hinterlegt!
            </div>
            <div className="rounded-xl p-20 bg-lightGray mt-10 flex-1 flex flex-col">
              {changedUserData?.fullName !== undefined && (
                <AnamnesePersonalInformation
                  userData={changedUserData}
                  changeUserDataFunction={setChangedUserData}
                  saveUserDataFunction={saveUserData}
                />
              )}
              <div className="mt-25">
                <Button
                  className="py-10 text-12"
                  onClick={() => saveUserData()}
                  isPending={userSaveLoaded}
                  disabled={userSaveLoaded}
                >
                  Änderungen speichern
                </Button>
              </div>
            </div>
          </div>

          <div className="flex-1 flex flex-col">
            <div className="font-bold text-25">Weitere Eigenschaften</div>
            <div className="font-extralight text-13">
              Hier können Sie weitere Informationen über den Nutzer hinterlegen.
            </div>
            <div className="font-extralight text-13">
              Diese Daten sind für interne Zwecke gedacht und nur für Sie einsehbar!
            </div>
            <div className="rounded-xl p-20 bg-lightGray mt-10 flex-1 flex flex-col">
              <AnamneseCustomInformation userData={userData} changeUserDataFunction={setChangedUserData} />
              <div className="mt-20">
                <Button
                  className="py-10 text-12"
                  onClick={() => saveUserData()}
                  isPending={userSaveLoaded}
                  disabled={userSaveLoaded}
                >
                  Änderungen speichern
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-40 flex gap-40">
          <div className="flex-1 flex flex-col">
            <div className="font-bold text-25">Ernährungs-Profil</div>
            <div className="font-extralight text-13">
              Diese Daten werden vom Nutzer bei der Registrierung oder beim Ändern des persönlichen Profils hinterlegt!
            </div>
            <div className="rounded-xl p-20 bg-lightGray mt-10 flex-1 flex flex-col">
              <div className="flex-grow">
                <CustomSelect
                  name="formOfNutrition"
                  dropDownOption={formOfNutritionOptions}
                  defaultValue={defaultFormOfNutritionValue}
                  label="Ernährungsform"
                  onChange={formOfNutritionChange}
                  isSearchable
                  className="w-full border border-textColor"
                />
                <div className="mt-20">
                  <CustomSelect
                    name="goal"
                    dropDownOption={goalOptions}
                    defaultValue={defaultGoalValue}
                    onChange={goalChange}
                    label="Ziel"
                    isSearchable
                    className="w-full border border-textColor"
                  />
                </div>
              </div>
              <div className="mt-20">
                <Button
                  className="py-10 text-12"
                  onClick={() => saveUserData()}
                  isPending={userSaveLoaded}
                  disabled={userSaveLoaded}
                >
                  Änderungen speichern
                </Button>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col">
            <div className="font-bold text-25">Unverträglichkeiten & Essverhalten</div>
            <div className="font-extralight text-13">
              Diese Daten werden vom Nutzer bei der Registrierung oder beim Ändern des persönlichen Profils hinterlegt!
            </div>
            <div className="rounded-xl p-20 bg-lightGray mt-10 flex-1 flex flex-col">
              <div className="flex-grow">
                <div>
                  <label className="block text-sm font-regular text-textColor opacity-50 mb-1">
                    Unverträglichkeiten
                  </label>
                  {userData?.incompatibilities && userData?.incompatibilities.length > 0 ? (
                    <div>
                      {incompatibilitiesSearchVisible ? (
                        <div className="relative">
                          <CustomSelect
                            name="notGoal"
                            dropDownOption={filteredIntolerances}
                            isSearchable
                            isMulti
                            onChange={(value: any) => changeIncompatibilitiesGroup(value)}
                            thisRef={selectIncompatibilitiesRef}
                            className="w-full border border-textColor"
                            isFullSize
                          />
                          <div className="absolute right-0 top-50">
                            <div className="bg-bgColor p-1 flex gap-1">
                              <div className="bg-lightGrayDarker">
                                <CheckIcon
                                  width={22}
                                  height={22}
                                  className="text-buttonTextColor cursor-pointer"
                                  onClick={() => saveIncompatibilities()}
                                />
                              </div>
                              <div className="bg-lightGrayDarker">
                                <XIcon
                                  width={22}
                                  height={22}
                                  className="text-buttonTextColor cursor-pointer"
                                  onClick={() => resetIncompatibilities()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-wrap gap-10">
                          {changedUserData?.incompatibilities?.map((item, index) => (
                            <div
                              key={index}
                              className="rounded-3xl bg-accentColor py-5 pl-15 pr-30 relative font-bold text-14"
                            >
                              <div>
                                <div className="text-buttonTextColor">{t(item)}</div>
                                <div className={styles.editIcon}>
                                  <XIcon
                                    width={15}
                                    height={15}
                                    className="text-buttonTextColor cursor-pointer"
                                    onClick={() => deleteIncompatibilitiesList(item)}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                          <div>
                            <PlusCircleIcon
                              width={30}
                              height={30}
                              className="text-accentColor cursor-pointer"
                              onClick={() => setIncompatibilitiesSearchVisible(true)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {incompatibilitiesSearchVisible ? (
                        <div className="relative">
                          <CustomSelect
                            name="notGoal"
                            dropDownOption={filteredIntolerances}
                            isSearchable
                            isMulti
                            onChange={(value: any) => changeIncompatibilitiesGroup(value)}
                            thisRef={selectIncompatibilitiesRef}
                            className="w-full border border-textColor"
                            isFullSize
                          />
                          <div className="absolute right-0 top-50">
                            <div className="bg-bgColor p-1 flex gap-1">
                              <div className="bg-lightGrayDarker">
                                <CheckIcon
                                  width={22}
                                  height={22}
                                  className="text-buttonTextColor cursor-pointer"
                                  onClick={() => saveIncompatibilities()}
                                />
                              </div>
                              <div className="bg-lightGrayDarker">
                                <XIcon
                                  width={22}
                                  height={22}
                                  className="text-buttonTextColor cursor-pointer"
                                  onClick={() => resetIncompatibilities()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-wrap gap-10">
                          <div className="my-auto">Aktuell sind keine Unverträglichkeiten hinterlegt!</div>
                          <div>
                            <PlusCircleIcon
                              width={30}
                              height={30}
                              className="text-accentColor cursor-pointer"
                              onClick={() => setIncompatibilitiesSearchVisible(true)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <hr className="border-lightGrayDarker mt-20 pb-5" />
                </div>
                <div className="mt-15">
                  <label className="block text-sm font-regular text-textColor opacity-50 mb-1">Essverhalten</label>
                  {userData?.eatingHabits && userData?.eatingHabits.length > 0 ? (
                    <div>
                      {eatingHabitsSearchVisible ? (
                        <div className="relative">
                          <CustomSelect
                            name="notGoal"
                            dropDownOption={filteredEatingHabits}
                            isSearchable
                            isMulti
                            onChange={(value: any) => changeEatingHabitsGroup(value)}
                            thisRef={selectEatingHabitsRef}
                            className="w-full border border-textColor"
                            isFullSize
                          />
                          <div className="absolute right-0 top-50">
                            <div className="bg-bgColor p-1 flex gap-1">
                              <div className="bg-lightGrayDarker">
                                <CheckIcon
                                  width={22}
                                  height={22}
                                  className="text-buttonTextColor cursor-pointer"
                                  onClick={() => saveEatingHabits()}
                                />
                              </div>
                              <div className="bg-lightGrayDarker">
                                <XIcon
                                  width={22}
                                  height={22}
                                  className="text-buttonTextColor cursor-pointer"
                                  onClick={() => resetEatingHabits()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-wrap gap-10">
                          {changedUserData?.eatingHabits?.map((item, index) => (
                            <div
                              key={index}
                              className="rounded-3xl bg-accentColor py-5 pl-15 pr-30 relative font-bold text-14"
                            >
                              <div>
                                <div className="text-buttonTextColor">{t(item)}</div>
                                <div className={styles.editIcon}>
                                  <XIcon
                                    width={15}
                                    height={15}
                                    className="text-buttonTextColor cursor-pointer"
                                    onClick={() => deleteEatingHabitsList(item)}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                          <div>
                            <PlusCircleIcon
                              width={30}
                              height={30}
                              className="text-accentColor cursor-pointer"
                              onClick={() => setEatingHabitsSearchVisible(true)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {eatingHabitsSearchVisible ? (
                        <div className="relative">
                          <CustomSelect
                            name="notGoal"
                            dropDownOption={filteredEatingHabits}
                            isSearchable
                            isMulti
                            onChange={(value: any) => changeEatingHabitsGroup(value)}
                            thisRef={selectEatingHabitsRef}
                            className="w-full border border-textColor"
                            isFullSize
                          />
                          <div className="absolute right-0 top-50">
                            <div className="bg-bgColor p-1 flex gap-1">
                              <div className="bg-lightGrayDarker">
                                <CheckIcon
                                  width={22}
                                  height={22}
                                  className="text-buttonTextColor cursor-pointer"
                                  onClick={() => saveEatingHabits()}
                                />
                              </div>
                              <div className="bg-lightGrayDarker">
                                <XIcon
                                  width={22}
                                  height={22}
                                  className="text-buttonTextColor cursor-pointer"
                                  onClick={() => resetEatingHabits()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-wrap gap-10">
                          <div className="my-auto">Aktuell sind keine Vorlieben zum Essverhalten hinterlegt!</div>
                          <div>
                            <PlusCircleIcon
                              width={30}
                              height={30}
                              className="text-accentColor cursor-pointer"
                              onClick={() => setEatingHabitsSearchVisible(true)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-40">
                <Button
                  className="py-10 text-12"
                  onClick={() => saveUserData()}
                  isPending={userSaveLoaded}
                  disabled={userSaveLoaded}
                >
                  Änderungen speichern
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachingDashboardAnamnese;
