import React, { createContext, ReactNode, useEffect, useState } from 'react';
import Loading from 'react-loading';
import { physicalStrainOption, yourGoal } from 'shared/constants/profile-wizard';
import firebase from 'services/firebase';
import { getIsWebView } from 'shared/functions/global';
import { defaultTextColor } from 'layouts/Theme';

type ContextProps = {
  user?: firebase.User | null;
  userData?: UserInfo;
  authenticated?: boolean;
  setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
  setUserData: React.Dispatch<React.SetStateAction<UserInfo>>;
  loadingAuthState?: boolean;
  tenant: string;
  tenantData?: TenantInfo;
  setTenantData: React.Dispatch<React.SetStateAction<TenantInfo>>;
  companyData?: CompanyInfo;
  setCompanyData: React.Dispatch<React.SetStateAction<CompanyInfo>>;
  theme?: TenantTheme;
  setTheme: React.Dispatch<React.SetStateAction<TenantTheme>>;
  member?: UserInfo[];
  userDomain: string;
};

type Props = {
  children: ReactNode;
};

export const AuthContext = createContext<ContextProps>({
  setUser: () => {},
  setUserData: () => {},
  tenant: '',
  setTenantData: () => {},
  setCompanyData: () => {},
  setTheme: () => {},
  userDomain: '',
});

const dCaloriesList = [-500, 250, 0];
const today = new Date();
today.setHours(0, 0, 0, 0);

export const AuthProvider = ({ children }: Props) => {
  const userDomain = window.location.hostname;
  const [user, setUser] = useState<firebase.User | null>(null);
  const [userData, setUserData] = useState<UserInfo>({} as UserInfo);
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [tenant, setTenant] = useState<string>('');
  const [tenantData, setTenantData] = useState<TenantInfo>({} as TenantInfo);
  const [companyData, setCompanyData] = useState<CompanyInfo>({} as CompanyInfo);
  const [theme, setTheme] = useState<TenantTheme>({} as TenantTheme);
  const [member, setMember] = useState<UserInfo[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const updateUserData = async (authUser: firebase.User | null) => {
    setLoadingAuthState(true);
    try {
      if (authUser) {
        let tenantName = authUser?.displayName ?? 'tortija';
        if (tenantName === 'tortija' && sessionStorage.getItem('tenant') !== null) {
          tenantName = sessionStorage.getItem('tenant')!;
          sessionStorage.removeItem('tenant');
        }
        setTenant(tenantName);
        setAuthenticated(authUser !== null);
        const userDoc = await firebase.firestore().collection(`tenants/${tenantName}/users`).doc(authUser?.uid).get();
        const userInfo = { uid: userDoc.id, ...userDoc.data() };
        setUserData(userInfo as UserInfo);
        setUser(authUser);
        const tenantInfo = (await firebase.firestore().collection(`tenants`).doc(tenantName).get()).data();
        setTenantData(tenantInfo as TenantInfo);
        setCompanyData(tenantInfo?.company as CompanyInfo);
        setTheme(tenantInfo?.theme as TenantTheme);

        const favicon = document.getElementById('favicon') as any;

        if (tenantName !== 'tortija') {
          localStorage.setItem('layoutTheme', JSON.stringify(tenantInfo?.theme));
          localStorage.setItem('layoutCompanyName', JSON.stringify(tenantInfo?.company?.companyName));
        } else {
          document.title = 'Tortija';
          if (!getIsWebView()) {
            favicon.href = `${process.env.PUBLIC_URL}/favicon_old.ico`;
          }

          localStorage.removeItem('layoutTheme');
          localStorage.removeItem('layoutCompanyName');
        }

        if (
          (userInfo as UserInfo)?.role === 4 ||
          (userInfo as UserInfo)?.role === 3 ||
          (userInfo as UserInfo)?.role === 6
        ) {
          const membersData = await firebase.firestore().collection(`tenants/${tenantName}/users`).get();
          const members = membersData.docs.map(item => ({ uid: item.id, ...item.data() }));
          setMember(members as UserInfo[]);
        }

        // send user uid to RN
        // @ts-ignore
        window?.ReactNativeWebView?.postMessage(JSON.stringify({ uid: userDoc.id }));

        if (userDoc.exists) {
          // update last login date
          if (!(userInfo as UserInfo)?.lastLoginDate || (userInfo as UserInfo)?.lastLoginDate < today) {
            await firebase
              .firestore()
              .collection(`tenants/${tenantName}/users`)
              .doc(authUser?.uid)
              .update({ lastLoginDate: new Date() });
          }
        }
      } else {
        setAuthenticated(false);
      }
      setLoadingAuthState(false);
    } catch (error: any) {
      console.error(error);
      setLoadingAuthState(false);
    }
  };

  useEffect(() => {
    const age = parseFloat(userData?.age ?? '') ?? 0;
    const height = parseFloat(userData?.bodySize ?? '') ?? 0;
    const weight = parseFloat(userData?.bodyWeight ?? '') ?? 0;
    let calories = 0;
    if (userData?.gender === 'man') {
      calories = 10 * weight + 6.25 * height - 5 * age + 5;
    } else if (userData?.gender === 'woman') {
      calories = 10 * weight + 6.25 * height - 5 * age - 161;
    }
    const pal = physicalStrainOption.find((item: any) => item.value === userData?.physicalStrainChange)?.pal ?? 1;
    calories *= pal;

    const dCalories =
      userData?.goal !== undefined ? dCaloriesList[yourGoal.findIndex(goal => goal === userData.goal)] ?? 0 : 0;
    calories += dCalories;

    let carbohydratesPercent = 50;
    let proteinPercent = 25;
    let fatPercent = 25;

    if (tenantData?.settings?.makros?.carbohydrates_percent !== undefined) {
      carbohydratesPercent = tenantData?.settings?.makros?.carbohydrates_percent;
    }

    if (tenantData?.settings?.makros?.protein_percent !== undefined) {
      proteinPercent = tenantData?.settings?.makros?.protein_percent;
    }

    if (tenantData?.settings?.makros?.fat_percent !== undefined) {
      fatPercent = tenantData?.settings?.makros?.fat_percent;
    }

    let carbohydrates = Math.round((calories * carbohydratesPercent) / 100 / 4.1);
    let protein = Math.round((calories * proteinPercent) / 100 / 4.1);
    let fat = Math.round((calories * fatPercent) / 100 / 9.3);

    if (
      tenantData?.settings?.functions?.caloriesGoal !== undefined &&
      tenantData?.settings?.functions?.caloriesGoal === false &&
      userData?.profileComplete !== undefined &&
      userData?.profileComplete === true
    ) {
      calories = parseFloat(userData?.calories);
      carbohydrates = userData?.carbohydrates_total;
      protein = userData?.protein_total;
      fat = userData?.fat_total;
    }

    if (
      tenantData?.settings?.makros?.carbohydrates_percent !== undefined ||
      tenantData?.settings?.makros?.protein_percent !== undefined ||
      tenantData?.settings?.makros?.fat_percent !== undefined
    ) {
      setUserData((prevProfile: UserInfo) => ({
        ...prevProfile,
        calories: calories.toFixed(2),
        carbohydrates_total: carbohydrates,
        protein_total: protein,
        fat_total: fat,
        custom_nutrition_type: 'user',
        custom_carbohydrates_percent:
          tenantData?.settings?.makros?.carbohydrates_percent !== undefined
            ? tenantData?.settings?.makros?.carbohydrates_percent.toString()
            : '50',
        custom_protein_percent:
          tenantData?.settings?.makros?.protein_percent !== undefined
            ? tenantData?.settings?.makros?.protein_percent.toString()
            : '25',
        custom_fat_percent:
          tenantData?.settings?.makros?.fat_percent !== undefined
            ? tenantData?.settings?.makros?.fat_percent.toString()
            : '25',
      }));
    } else {
      setUserData((prevProfile: UserInfo) => ({
        ...prevProfile,
        calories: calories.toFixed(2),
        carbohydrates_total: carbohydrates,
        protein_total: protein,
        fat_total: fat,
      }));
    }
  }, [
    userData?.age,
    userData?.bodySize,
    userData?.bodyWeight,
    userData?.gender,
    userData?.goal,
    userData?.physicalStrainChange,
  ]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((authUser: firebase.User | null) => {
      updateUserData(authUser as firebase.User);
    });

    const favicon = document.getElementById('favicon') as any;

    const getLocalStorageTheme = localStorage.getItem('layoutTheme');
    const getLocalStorageCompanyName = localStorage.getItem('layoutCompanyName');

    if (window.location.pathname.includes('auth')) {
      // search for customDomain inside tenants which is equal to userDomain
      firebase
        .firestore()
        .collection('customDomain-infos')
        .where('customDomain', '==', userDomain)
        .get()
        .then(querySnapshot => {
          if (querySnapshot.size > 0) {
            const tenantInfo = querySnapshot.docs[0].data();
            setTheme(tenantInfo?.theme as TenantTheme);
            setTenantData(tenantInfo as TenantInfo);

            if (favicon !== null && tenantInfo?.theme?.logo && !getIsWebView()) {
              favicon.href = tenantInfo?.theme?.logo;
            }

            if (tenantInfo?.company?.companyName !== undefined) {
              document.title = tenantInfo?.company?.companyName;
            }
          } else {
            document.title = 'Tortija';
            if (!getIsWebView()) {
              favicon.href = `${process.env.PUBLIC_URL}/favicon_old.ico`;
            }
          }
          setIsLoading(false);
        });
    } else if (getLocalStorageTheme && getLocalStorageTheme !== 'undefined') {
      const themeData = JSON.parse(getLocalStorageTheme) as TenantTheme;
      if (getLocalStorageCompanyName) {
        const companyName = JSON.parse(getLocalStorageCompanyName);

        if (getLocalStorageCompanyName !== undefined) {
          document.title = companyName;
        }
      }

      setTheme(themeData);

      if (favicon !== null && themeData?.logo && !getIsWebView()) {
        favicon.href = themeData?.logo;
      }

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <Loading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        userData,
        authenticated,
        setUser,
        setUserData,
        loadingAuthState,
        tenant,
        tenantData,
        setTenantData,
        companyData,
        setCompanyData,
        theme,
        setTheme,
        member,
        userDomain,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
