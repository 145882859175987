/* eslint-disable react/no-danger */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { useForm } from 'react-hook-form';
import Input from 'components/Input';
import axios from 'axios';
import firebase from 'services/firebase';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { toast } from 'react-toast';
import { loadStripe } from '@stripe/stripe-js/pure';
import moment from 'moment';
import { emailChecker, passwordChecker } from 'services/validations';
import ReactLoading from 'react-loading';
import { useTranslation } from 'react-i18next';
import { defaultTextColor } from 'layouts/Theme';
import { BookOpenIcon, TrashIcon } from '@heroicons/react/outline';
import LogoImg from 'assets/img/svg/logo.svg';
import Overlay from 'components/Overlay';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import ButtonBack from 'components/ButtonBack';
import { getMobileOperatingSystem, getIsWebView, remainTrialConnectDay } from 'shared/functions/global';
import { ROLE_COMPANY_ADMIN } from 'shared/constants/global';
import CheckoutFormCustomerBilling from '../payment/checkout';
import styles from './styles.module.scss';

type ParamsType = {
  tenant: string;
};

const CustomerBillingRegister: React.FC = () => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const { tenant } = useParams<ParamsType>();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const history = useHistory();
  const db = firebase.firestore();

  const [paymentIntent, setPaymentIntent] = useState<any>(null);
  const [stripePromise, setStripePromise] = useState<any>(null);

  const [activate, setActivate] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);
  const { theme, setTheme, userData, tenantData } = useContext(AuthContext);
  const [pageLoadedAndVerified, setPageLoadedAndVerified] = useState(false);
  const [stripeConnectData, setStripeConnectData] = useState<any>({});
  const [localTenantData, setLocalTenantData] = useState<any>({});
  const [verifyStatus, setVerifyStatus] = useState(false);
  const [stripeLibLoaded, setStripeLibLoaded] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isPendingTrial, setIsPendingTrial] = useState(false);
  const [openLegalPopupClass, setOpenLegalPopupClass] = useState('hidden');
  const [legalType, setLegalType] = useState('');
  const [deletePending, setDeletePending] = useState(false);
  const logo = theme?.logo ?? LogoImg;
  const [remainDayCounter, setRemainDayCounter] = useState(-1);

  const [deleteProfilePopupClass, setDeleteProfilePopupClass] = useState('hidden');

  const [currentName, setCurrentName] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');

  const password = useRef({});
  password.current = watch('password', '');

  useEffect(() => {
    const getData = async () => {
      const getTenantData = (await firebase.firestore().collection('customDomain-infos').doc(tenant).get()).data();
      if (getTenantData === undefined) {
        setPageLoadedAndVerified(true);
      } else {
        if (
          getTenantData?.stripeConnect?.stripeConnectAccountId !== undefined &&
          getTenantData?.stripeConnect?.stripeConnectMonthlyFee !== undefined &&
          getTenantData?.stripeConnect?.stripeConnectPriceId !== undefined
        ) {
          setTheme(getTenantData?.theme as TenantTheme);
          setStripeConnectData(getTenantData?.stripeConnect);
          setLocalTenantData(getTenantData);

          setStripePromise(
            loadStripe(
              process.env.REACT_APP_STRIPE_PUBLICKEY ?? '',
              { stripeAccount: getTenantData?.stripeConnect?.stripeConnectAccountId } ?? ''
            )
          );

          setVerifyStatus(true);
        }

        setPageLoadedAndVerified(true);
      }
    };

    if (pageLoadedAndVerified === false) {
      getData();
      localStorage.removeItem('name');
      localStorage.removeItem('accountId');
      localStorage.removeItem('userMail');
      localStorage.removeItem('userPw');
    }
  }, []);

  useEffect(() => {
    if (
      userData?.fullName !== undefined &&
      userData?.membership?.paymentMethod === 'stripeConnect' &&
      (userData?.membership?.type === 'free' || userData?.membership?.type === 'canceled')
    ) {
      setPageLoadedAndVerified(true);
      setVerifyStatus(true);
    } else if (userData?.role === ROLE_COMPANY_ADMIN) {
      setVerifyStatus(true);
    } else if (userData?.fullName !== undefined) {
      setVerifyStatus(false);
    } else if (userData?.membership?.paymentMethod === 'stripeConnect' && userData?.membership?.type === 'pro') {
      setVerifyStatus(false);
    }

    if (userData?.fullName !== undefined && userData?.membership?.nextDate) {
      const remainDay = remainTrialConnectDay(userData?.created?.seconds, userData?.membership?.nextDate);

      setRemainDayCounter(remainDay);
    }
  }, [userData]);

  useEffect(() => {
    if (tenantData?.stripeConnect !== undefined) {
      setStripeConnectData(tenantData?.stripeConnect);
      setLocalTenantData(tenantData);

      if (stripeLibLoaded === false) {
        setStripePromise(
          loadStripe(
            process.env.REACT_APP_STRIPE_PUBLICKEY ?? '',
            { stripeAccount: tenantData?.stripeConnect?.stripeConnectAccountId } ?? ''
          )
        );
      }

      setStripeLibLoaded(true);
    }
  }, [tenantData]);

  const jumpBack = () => {
    setActivate(false);
    setCurrentStep(1);
  };

  const onSubmit = async (data: any) => {
    setIsPending(true);
    setCurrentName(data.fullName);
    setCurrentEmail(data.email);
    setCurrentPassword(data.password);

    if (!activate) {
      toast.error('Du musst den Nutzungsbedingungen und der Datenschutzrichtlinie zustimmen.');
      setIsPending(false);
      return;
    }

    const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(data.email);

    if (signInMethods.length > 0) {
      setIsPending(false);
      toast.error('Unter dieser Email-Adresse existiert bereits ein Nutzer!');
      setIsPending(false);
      return;
    }

    if (
      stripeConnectData?.stripeConnectTrialType !== undefined &&
      stripeConnectData?.stripeConnectTrialType === 'withoutPayment' &&
      stripeConnectData?.stripeConnectTrialDays !== undefined
    ) {
      registerUserWithoutPayment(data.email, data.password, data.fullName);
    } else {
      const res = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/subscribeConnect`, {
        user: { email: data.email, name: data.fullName },
        planID: stripeConnectData?.stripeConnectPriceId,
        stripeAccountId: stripeConnectData?.stripeConnectAccountId,
        taxId: stripeConnectData?.stripeConnectTaxId,
        smallCompany: stripeConnectData?.stripeConnectIsSmallCompany,
        trialDays: stripeConnectData?.stripeConnectTrialDays ?? 0,
        footer: stripeConnectData?.stripeConnectIsSmallCompany
          ? 'Gemäß § 19 UStG wird keine Umsatzsteuer berechnet.'
          : '',
      });

      setPaymentIntent(res.data);

      sessionStorage.setItem('subscriptionId', res.data.subscriptionId);
      sessionStorage.setItem('customerId', res.data.customerId);

      setIsPending(false);

      setCurrentStep(2);
    }
  };

  const paymentStepTrial = async () => {
    setIsPendingTrial(true);

    const res = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/subscribeConnect`, {
      user: { email: userData?.email, name: userData?.fullName },
      planID: tenantData?.stripeConnect?.stripeConnectPriceId,
      stripeAccountId: tenantData?.stripeConnect?.stripeConnectAccountId,
      taxId: tenantData?.stripeConnect?.stripeConnectTaxId,
      smallCompany: tenantData?.stripeConnect?.stripeConnectIsSmallCompany,
      footer: tenantData?.stripeConnect?.stripeConnectIsSmallCompany
        ? 'Gemäß § 19 UStG wird keine Umsatzsteuer berechnet.'
        : '',
    });

    setPaymentIntent(res.data);

    console.log(res.data);

    sessionStorage.setItem('subscriptionId', res.data.subscriptionId);
    sessionStorage.setItem('customerId', res.data.customerId);

    if (authContext.user?.uid !== undefined) {
      sessionStorage.setItem('tempUid', authContext.user?.uid);
    }

    setIsPendingTrial(false);

    setCurrentStep(2);
  };

  const openLegalInfo = (thisLegalType: string) => {
    setLegalType(thisLegalType);
    setOpenLegalPopupClass('block');
  };

  const clickActivate = () => {
    if (activate) {
      setActivate(false);
    } else {
      setActivate(true);
    }
  };

  const logOutUser = async () => {
    try {
      await firebase.auth().signOut();
      history.push('/');
      window.location.reload();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const registerUserWithoutPayment = async (userMail: string, userPw: string, userFullName: string) => {
    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(userMail, userPw)
        .then(async userCredential => {
          const collection = `tenants/${tenant}/users`;

          await userCredential.user?.updateProfile({
            displayName: tenant,
          });

          await db
            .collection(collection)
            .doc(userCredential.user?.uid)
            .set({
              email: userMail,
              fullName: userFullName,
              gymID: '',
              role: 5,
              userAgent: getMobileOperatingSystem(),
              profileComplete: false,
              cookieBannerType: 'initial',
              created: firebase.firestore.FieldValue.serverTimestamp(),
              withCouponCode: false,
              membership: {
                id: '',
                activated: true,
                payerId: '',
                stripeCustomerId: '',
                nextDate: stripeConnectData?.stripeConnectTrialDays ?? 0,
                paymentMethod: 'stripeConnect',
                type: 'free',
              },
              uid: userCredential.user?.uid,
            });

          await db
            .collection('tenantUserByDateExpired')
            .doc()
            .set({
              uid: userCredential.user?.uid,
              tenant,
              nextDate: moment()
                .utc()
                .set({ hour: 0, minute: 0, second: 0 })
                .add(parseFloat(stripeConnectData?.stripeConnectTrialDays ?? 0), 'days')
                .unix(),
            });
        })
        .catch(error => {
          console.error(error.message);
          toast.error('5');
          toast.error(error.message);
        });

      // Send Mail to user
      let invitationMailText = `Hallo ${userFullName},\n\ndu wurdest zu der Ernährungssoftware von ${
        localTenantData?.company?.companyName
      } eingeladen.\n\nBitte klicke auf den folgenden Link, um weitere Informationen zu erhalten:\n${
        window.location.hostname === 'demo.tortija.de'
          ? 'https://whitelabel.tortija.de/demo/'
          : localTenantData?.invitationLink !== undefined
          ? localTenantData?.invitationLink
          : 'https://whitelabel.tortija.de/einladung/'
      }\n\nViel Spaß mit unserer Ernährungssoftware!`;

      if (
        localTenantData?.settings?.invitationMail?.mailText !== undefined &&
        localTenantData?.settings?.invitationMail?.mailText?.length > 0
      ) {
        invitationMailText = `Hallo ${userFullName},\n\n${localTenantData?.settings?.invitationMail?.mailText}`;
      }

      const adminMailText = `Neue Registrierung über Tortija Billing:\n\nName: ${userFullName}\n\nEmail: ${userMail}`;

      axios({
        url: `${process.env.REACT_APP_API_URL}/sendTextMail`,
        method: 'post',
        data: {
          mailSubject:
            localTenantData?.settings?.invitationMail?.mailSubject !== undefined &&
            localTenantData?.settings?.invitationMail?.mailSubject?.length > 0
              ? localTenantData?.settings?.invitationMail?.mailSubject
              : `${localTenantData?.company?.companyName} Einladung`,
          mailTo: userMail,
          mailText: invitationMailText,
          mailFrom:
            localTenantData?.settings?.invitationMail?.mailFrom !== undefined &&
            localTenantData?.settings?.invitationMail?.mailFrom?.length > 0
              ? localTenantData?.settings?.invitationMail?.mailFrom
              : 'info@tortija.de',
          mailName:
            localTenantData?.settings?.invitationMail?.mailName !== undefined &&
            localTenantData?.settings?.invitationMail?.mailName?.length > 0
              ? localTenantData?.settings?.invitationMail?.mailName
              : '',
        },
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(
        response => {
          console.log(response);
        },
        error => {
          console.log(error);
        }
      );

      const adminMail =
        stripeConnectData.stripeConnectRegisterEmail !== undefined
          ? stripeConnectData.stripeConnectRegisterEmail
          : localTenantData?.company?.email;

      if (adminMail !== undefined) {
        axios({
          url: `${process.env.REACT_APP_API_URL}/sendCustomTextMail`,
          method: 'post',
          data: {
            mailSubject: `Tortija Billing: Neue Registrierung`,
            mailTo: adminMail,
            mailText: adminMailText,
            mailFrom: 'info@tortija.de',
          },
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }).then(
          response => {
            console.log(response);
          },
          error => {
            console.log(error);
          }
        );
      }

      history.push('/store-landingpage');
      window.location.reload();
    } catch (error) {
      history.push('/store-landingpage');
      window.location.reload();
      console.log('error: ', error);
    }
  };

  const deleteUserAccount = async () => {
    setDeletePending(true);
    try {
      const profile: UserInfo = authContext.userData as UserInfo;

      await db
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .update({
          ...profile,
          toDelete: true,
        });
      toast.success('Dein Profil wurde erfolgreich gelöscht!');
      logOutUser();
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn(error.message);
    }
  };

  return (
    <>
      <Overlay
        overlayClass={deleteProfilePopupClass}
        setOverlayClass={setDeleteProfilePopupClass}
        headline="Account löschen"
        icon={<TrashIcon width={25} height={25} className="text-accentColor mx-auto" />}
        fullSize
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div>Wollen Sie ihr Profil wirklich löschen?</div>
            <div>Alle gespeicherten Daten werden dabei gelöscht!</div>
          </div>
          {userData?.membership?.stripeCustomerId !== undefined && userData?.membership?.type === 'pro' && (
            <div className="pt-10">
              Sie werden an eine Seite weitergeleitet, wo Sie Ihren Account kündigen können. Nach Ablauf Ihres
              Abonnement-Zeitraums wird Ihr Account automatisch gelöscht!
            </div>
          )}
          <div className="pt-30 flex gap-30 pb-40">
            <div>
              <Button
                className="w-full"
                disabled={deletePending}
                isPending={deletePending}
                onClick={() => deleteUserAccount()}
              >
                Account löschen
              </Button>
            </div>
            <div>
              <Button className="w-full" onClick={() => setDeleteProfilePopupClass('hidden')}>
                Abbrechen
              </Button>
            </div>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={openLegalPopupClass}
        setOverlayClass={setOpenLegalPopupClass}
        headline={
          legalType === 'imprint'
            ? 'Impressum'
            : legalType === 'dataPrivacy'
            ? 'Datenschutzerklärung'
            : 'Nutzungsbedingungen'
        }
        icon={<BookOpenIcon width={25} height={25} className="text-accentColor mx-auto" />}
        fullSize
      >
        <div className="pb-50">
          <div className="pt-30 font-extralight text-base">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  legalType === 'imprint'
                    ? localTenantData?.legal?.imprintText.replace(/(?:\r\n|\r|\n)/g, '<br />')
                    : legalType === 'dataPrivacy'
                    ? localTenantData?.legal?.privacyPolicyText.replace(/(?:\r\n|\r|\n)/g, '<br />')
                    : localTenantData?.legal?.termsOfUseText.replace(/(?:\r\n|\r|\n)/g, '<br />'),
              }}
            />
          </div>
        </div>
      </Overlay>

      {pageLoadedAndVerified ? (
        <>
          {getIsWebView() === true ? (
            <div className="text-textColor m-auto flex flex-col items-center">
              <div className="pt-30">
                <img width={160} src={logo} alt="Logo" />
              </div>
              {remainDayCounter >= 0 ? (
                <h1 className="font-bold pt-60 text-35 desktop:w-1/2 desktop:px-20 text-center">
                  Dein Testzeitraum läuft bald ab
                </h1>
              ) : (
                <h1 className="font-bold pt-60 text-35 desktop:w-1/2 desktop:px-20 text-center">
                  Dein Testzeitraum ist abgelaufen
                </h1>
              )}
              <div className="pt-15 px-20">Dein Zugang zur App ist derzeit eingeschränkt.</div>
              <div className="pt-10 px-20">
                Bitte besuche unsere Website für weitere Informationen zu deinem Account und den nächsten Schritten.
              </div>
            </div>
          ) : verifyStatus === false ? (
            <div className="font-bold text-30 text-center pt-40">Dieses Formular ist aktuell nicht verfügbar</div>
          ) : (
            <div className="bg-bgColor mb-100 mx-20 text-textColor mt-11">
              {(userData?.fullName !== undefined &&
                userData?.membership?.paymentMethod === 'stripeConnect' &&
                (userData?.membership?.type === 'free' || userData?.membership?.type === 'canceled') &&
                currentStep === 1) ||
              (userData?.role === ROLE_COMPANY_ADMIN &&
                stripeConnectData?.stripeConnectTrialType !== undefined &&
                stripeConnectData?.stripeConnectTrialType === 'withoutPayment') ? (
                <div className="text-textColor m-auto flex flex-col items-center">
                  <Link
                    to={{
                      pathname: '/',
                    }}
                  >
                    {' '}
                    <ButtonBack
                      text="Zurück"
                      className="rounded-2xl pr-15 mb-20 border-transparent border-2 hover:border-accentColor"
                    />
                  </Link>

                  <img width={160} src={logo} alt="Logo" />

                  {userData?.membership?.type === 'canceled' ? (
                    <h1 className="font-bold pt-60 text-35 desktop:w-1/2 desktop:px-20 text-center">
                      Aktiviere jetzt deinen Account
                    </h1>
                  ) : remainDayCounter >= 0 ? (
                    <h1 className="font-bold pt-60 text-35 desktop:w-1/2 desktop:px-20 text-center">
                      Dein kostenloser Testzeitraum läuft bald ab
                    </h1>
                  ) : (
                    <h1 className="font-bold pt-60 text-35 desktop:w-1/2 desktop:px-20 text-center">
                      Dein kostenloser Testzeitraum ist abgelaufen
                    </h1>
                  )}
                  <div className="mx-auto max-w-lg overflow-hidden rounded-3xl bg-lightGray shadow-lg lg:flex lg:max-w-none mt-20">
                    <div className="flex-1 px-6 py-8 lg:p-12">
                      <h3 className="text-2xl font-bold text-textColor sm:text-3xl sm:tracking-tight">
                        Premium Zugang
                      </h3>
                      {tenantData?.stripeConnect?.stripeConnectTrialPremiumText !== undefined &&
                      tenantData?.stripeConnect?.stripeConnectTrialPremiumText.length > 0 ? (
                        <div
                          className="mt-6 text-base text-textColor font-extralight"
                          dangerouslySetInnerHTML={{
                            __html: tenantData?.stripeConnect?.stripeConnectTrialPremiumText.replace(
                              /(?:\r\n|\r|\n)/g,
                              '<br />'
                            ),
                          }}
                        />
                      ) : (
                        <p className="mt-6 text-base text-textColor font-extralight">Verlängere jetzt deinen Zugang!</p>
                      )}

                      <div className="mt-8">
                        <div className="flex items-center">
                          <h4 className="flex-shrink-0 bg-lightGray pr-4 text-base font-semibold text-textColor">
                            {t("What's included")}
                          </h4>
                          <div className="flex-1 border-t-2 border-textColor" />
                        </div>
                        {tenantData?.stripeConnect?.stripeConnectTrialFeatureText !== undefined &&
                        tenantData?.stripeConnect?.stripeConnectTrialFeatureText.length > 0 ? (
                          <div
                            className="font-extralight text-base pt-30"
                            dangerouslySetInnerHTML={{
                              __html: tenantData?.stripeConnect?.stripeConnectTrialFeatureText.replace(
                                /(?:\r\n|\r|\n)/g,
                                '<br />'
                              ),
                            }}
                          />
                        ) : (
                          <p className="font-extralight text-base pt-30">
                            Du hast in dem Premium Zugang Zugriff auf die vollen Funktionen von unserer App.
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="py-8 px-6 text-center lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-12 md:w-500 w-350 mx-auto">
                      <div className="mt-4 flex items-center justify-center text-5xl font-bold tracking-tight text-textColor">
                        <span className={styles.strikethrough}>
                          {tenantData?.stripeConnect?.stripeConnectMonthlyFee
                            ?.toFixed(2)
                            .toString()
                            .replaceAll('.', ',')}{' '}
                          €
                        </span>
                      </div>
                      <div>
                        <span className="pl-20 text-16">pro Monat</span>
                      </div>

                      <div className="mt-4">
                        <Button
                          buttonStyle="primary"
                          className="w-full mt-8"
                          onClick={() => paymentStepTrial()}
                          disabled={isPendingTrial || userData?.role === ROLE_COMPANY_ADMIN}
                          isPending={isPendingTrial}
                        >
                          Weiter zum nächsten Schritt
                        </Button>
                      </div>
                      <div className="mt-4 text-sm">
                        <div className="flex w-full mb-10">
                          <div className="flex-1 border-t-2 border-textColor my-auto mx-20" />
                          <div className="">oder</div>
                          <div className="flex-1 border-t-2 border-textColor my-auto mx-20" />
                        </div>
                        <div className="flex justify-center space-x-30">
                          <button
                            type="button"
                            className="font-medium text-textColor"
                            onClick={() => userData?.role !== ROLE_COMPANY_ADMIN && logOutUser()}
                          >
                            {t('Log out')}
                          </button>
                          <button
                            type="button"
                            className="font-medium text-textColor"
                            onClick={() => userData?.role !== ROLE_COMPANY_ADMIN && setDeleteProfilePopupClass('block')}
                          >
                            Account löschen
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : userData?.fullName !== undefined &&
                userData?.membership?.paymentMethod === 'stripeConnect' &&
                (userData?.membership?.type === 'free' || userData?.membership?.type === 'canceled') &&
                currentStep === 2 ? (
                <div className="text-textColor m-auto flex flex-col items-center">
                  <img width={160} src={logo} alt="Logo" />
                  <h1 className="font-bold pt-60 text-35 desktop:w-1/2 desktop:px-20 text-center">
                    Bitte wähle eine Zahlungsmethode
                  </h1>
                  <div className="pt-40 w-full desktop:w-1/3">
                    <div className={styles.link} onClick={() => jumpBack()} aria-hidden="true">
                      Zurück
                    </div>
                    <div className="pb-10">
                      <div className="font-bold text-25 pt-10">
                        <span className="pr-10">Kosten:</span>{' '}
                        {stripeConnectData?.stripeConnectMonthlyFee?.toFixed(2).toString().replaceAll('.', ',')} €
                        <span className="pl-10 text-12 my-auto">pro Monat</span>
                      </div>
                      <div className="pt-10">Dieses Abonnement kann monatlich gekündigt werden.</div>
                    </div>
                    {(paymentIntent?.clientSecret.payment_intent?.client_secret !== undefined ||
                      (paymentIntent?.clientSecret !== undefined && paymentIntent?.clientSecret?.includes('seti'))) && (
                      <Elements
                        key={stripeConnectData.stripeConnectPriceId}
                        stripe={stripePromise}
                        options={{
                          clientSecret: (() => {
                            if (typeof paymentIntent?.clientSecret === 'string') {
                              return paymentIntent.clientSecret.includes('seti')
                                ? paymentIntent.clientSecret
                                : paymentIntent.clientSecret.payment_intent?.client_secret;
                            }
                            return paymentIntent?.clientSecret?.payment_intent?.client_secret;
                          })(),
                          appearance: {
                            theme: 'stripe',
                            labels: 'floating',
                          },
                          locale: 'de',
                          loader: 'always',
                        }}
                      >
                        <CheckoutFormCustomerBilling
                          clientSecret={(() => {
                            if (typeof paymentIntent?.clientSecret === 'string') {
                              return paymentIntent.clientSecret.includes('seti')
                                ? paymentIntent.clientSecret
                                : paymentIntent.clientSecret.payment_intent?.client_secret;
                            }
                            return paymentIntent?.clientSecret?.payment_intent?.client_secret;
                          })()}
                          connectData={stripeConnectData}
                          userMail={userData?.email}
                          userName={userData?.fullName}
                          userPassword=""
                          businessName="stripeConnectTrial"
                          tenant={tenant}
                          adminMail={
                            stripeConnectData.stripeConnectRegisterEmail !== undefined
                              ? stripeConnectData.stripeConnectRegisterEmail
                              : tenantData?.company?.email
                          }
                          invitationLink={
                            tenantData?.invitationLink !== undefined
                              ? tenantData?.invitationLink
                              : 'https://whitelabel.tortija.de/einladung/'
                          }
                          mailSubject={
                            stripeConnectData?.stripeConnectMailSubject !== undefined &&
                            stripeConnectData?.stripeConnectMailSubject.length > 0
                              ? stripeConnectData?.stripeConnectMailSubject
                              : `${tenantData?.company?.companyName} Einladung`
                          }
                          mailFrom={
                            stripeConnectData?.stripeConnectMailFrom !== undefined &&
                            stripeConnectData?.stripeConnectMailFrom?.length > 0
                              ? stripeConnectData?.stripeConnectMailFrom
                              : 'info@tortija.de'
                          }
                          mailName={
                            stripeConnectData?.stripeConnectMailName !== undefined &&
                            stripeConnectData?.stripeConnectMailName?.length > 0
                              ? stripeConnectData?.stripeConnectMailName
                              : ''
                          }
                          mailText={
                            stripeConnectData?.stripeConnectMailText !== undefined &&
                            stripeConnectData?.stripeConnectMailText?.length > 0
                              ? stripeConnectData?.stripeConnectMailText
                              : ''
                          }
                        />
                      </Elements>
                    )}
                  </div>
                </div>
              ) : (
                <div className="text-textColor m-auto flex flex-col items-center">
                  <img width={160} src={logo} alt="Logo" />
                  <h1 className="font-bold pt-60 text-35 desktop:w-1/2 desktop:px-20 text-center">
                    {stripeConnectData?.stripeConnectRegisterHeadline !== undefined
                      ? stripeConnectData?.stripeConnectRegisterHeadline
                      : 'Registrierung'}
                  </h1>
                  <div className="font-extralight text-textColor text-20 pt-30 desktop:w-1/2 desktop:px-20 text-center">
                    {stripeConnectData?.stripeConnectRegisterText !== undefined ? (
                      <div
                        className="font-extralight"
                        dangerouslySetInnerHTML={{
                          __html: stripeConnectData?.stripeConnectRegisterText.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                        }}
                      />
                    ) : (
                      'Bitte fülle die Felder aus, um dich bei uns zu registrieren.'
                    )}
                  </div>
                  {stripeConnectData?.stripeConnectTrialType !== undefined &&
                    stripeConnectData?.stripeConnectTrialType === 'withoutPayment' &&
                    stripeConnectData?.stripeConnectTrialDays !== undefined && (
                      <div className="font-bold">
                        Kostenloser Testzeitraum: {stripeConnectData?.stripeConnectTrialDays} Tage
                      </div>
                    )}
                  <div className="pt-40 w-full desktop:w-1/3">
                    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                      {currentStep === 1 ? (
                        <>
                          <Input
                            name="fullName"
                            className="mb-20"
                            type="text"
                            label="Vollständiger Name"
                            autoComplete="given-name"
                            required
                            register={register('fullName', {
                              validate: (value: string) => {
                                if (!value.trim()) {
                                  return t('Please enter your full name').toString();
                                }
                                return true;
                              },
                            })}
                            error={errors.fullName}
                          />
                          <Input
                            name="email"
                            className="mb-20"
                            type="text"
                            label="E-Mail"
                            autoComplete="email"
                            required
                            register={register('email', {
                              validate: (value: string) => {
                                if (!value.trim()) {
                                  return t('Email Address is required').toString();
                                }
                                return emailChecker(value) || t('Please enter valid email address').toString();
                              },
                            })}
                            error={errors.email}
                          />
                          <Input
                            name="password"
                            type="password"
                            className="mb-20"
                            label={t('Password')}
                            autoComplete=""
                            required
                            register={register('password', {
                              minLength: {
                                value: 8,
                                message: 'Gebe mindestens 1 Großbuchstabe, 1 Zahl und 8 Zeichen ein.',
                              },
                              required: t('Please enter your password').toString(),
                              validate: (value: string) =>
                                passwordChecker(value) || t('Password must contain at least 1 number').toString(),
                            })}
                            error={errors.password}
                          />
                          <Input
                            name="confirm-password"
                            type="password"
                            label={t('Confirm password')}
                            className="mb-20"
                            autoComplete=""
                            register={register('confirm-password', {
                              required: t('Please confirm your password').toString(),
                              validate: (value: string) =>
                                value === password.current ||
                                t('Password and Confirm password do not match').toString(),
                            })}
                            error={errors['confirm-password']}
                          />
                          {stripeConnectData?.stripeConnectTrialType !== undefined &&
                            stripeConnectData?.stripeConnectTrialType === 'withoutPayment' &&
                            stripeConnectData?.stripeConnectTrialDays !== undefined && (
                              <div className="font-extralight text-12 text-center">
                                Eine Zahlungsmethode muss erst nach Ablauf des kostenlosen Testzeitraums von{' '}
                                {stripeConnectData?.stripeConnectTrialDays} Tagen hinterlegt werden.
                              </div>
                            )}
                          <div className="pt-10">
                            <Checkbox
                              onClick={() => clickActivate()}
                              label=" Durch Klicken auf den Button bestätigst du, dass du die Nutzungsbedingungen und die Datenschutzerklärung gelesen hast und
                        diesen zustimmst."
                              name="activate"
                              register="t"
                            />
                          </div>
                          <div>
                            <Button isPending={isPending} disabled={isPending} className="w-full mt-40">
                              {stripeConnectData?.stripeConnectTrialType !== undefined &&
                              stripeConnectData?.stripeConnectTrialType === 'withoutPayment' &&
                              stripeConnectData?.stripeConnectTrialDays !== undefined ? (
                                <span>Registrieren</span>
                              ) : (
                                <span>Zum nächsten Schritt</span>
                              )}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={styles.link} onClick={() => jumpBack()} aria-hidden="true">
                            Zurück
                          </div>
                          <div className="pb-10">
                            <div className="font-bold text-25 pt-10">
                              <span className="pr-10">Monatsbeitrag:</span>{' '}
                              {stripeConnectData?.stripeConnectMonthlyFee?.toFixed(2).toString().replaceAll('.', ',')} €
                              {stripeConnectData.stripeConnectTrialDays !== undefined && (
                                <div>
                                  <div className="font-bold text-25 pt-5">
                                    <span className="pr-10">Kostenloser Testzeitraum:</span>{' '}
                                    {stripeConnectData.stripeConnectTrialDays} Tage
                                  </div>
                                  <div className="font-extralight text-12">
                                    Dein Konto wird zum ersten mal nach {stripeConnectData.stripeConnectTrialDays} Tagen
                                    belastet.
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="pt-10">Dieses Abonnement kann monatlich gekündigt werden.</div>
                          </div>
                          {(paymentIntent?.clientSecret.payment_intent?.client_secret !== undefined ||
                            (paymentIntent?.clientSecret !== undefined &&
                              paymentIntent?.clientSecret?.includes('seti'))) && (
                            <Elements
                              key={stripeConnectData.stripeConnectPriceId}
                              stripe={stripePromise}
                              options={{
                                clientSecret: (() => {
                                  if (typeof paymentIntent?.clientSecret === 'string') {
                                    return paymentIntent.clientSecret.includes('seti')
                                      ? paymentIntent.clientSecret
                                      : paymentIntent.clientSecret.payment_intent?.client_secret;
                                  }
                                  return paymentIntent?.clientSecret?.payment_intent?.client_secret;
                                })(),
                                appearance: {
                                  theme: 'stripe',
                                  labels: 'floating',
                                },
                                locale: 'de',
                                loader: 'always',
                              }}
                            >
                              <CheckoutFormCustomerBilling
                                clientSecret={(() => {
                                  if (typeof paymentIntent?.clientSecret === 'string') {
                                    return paymentIntent.clientSecret.includes('seti')
                                      ? paymentIntent.clientSecret
                                      : paymentIntent.clientSecret.payment_intent?.client_secret;
                                  }
                                  return paymentIntent?.clientSecret?.payment_intent?.client_secret;
                                })()}
                                connectData={stripeConnectData}
                                userMail={currentEmail}
                                userName={currentName}
                                userPassword={currentPassword}
                                businessName={localTenantData.company.companyName}
                                tenant={tenant}
                                adminMail={
                                  stripeConnectData.stripeConnectRegisterEmail !== undefined
                                    ? stripeConnectData.stripeConnectRegisterEmail
                                    : localTenantData?.company?.email
                                }
                                invitationLink={
                                  localTenantData?.invitationLink !== undefined
                                    ? localTenantData?.invitationLink
                                    : 'https://whitelabel.tortija.de/einladung/'
                                }
                                mailSubject={
                                  stripeConnectData?.stripeConnectMailSubject !== undefined &&
                                  stripeConnectData?.stripeConnectMailSubject.length > 0
                                    ? stripeConnectData?.stripeConnectMailSubject
                                    : `${localTenantData?.company?.companyName} Einladung`
                                }
                                mailFrom={
                                  stripeConnectData?.stripeConnectMailFrom !== undefined &&
                                  stripeConnectData?.stripeConnectMailFrom?.length > 0
                                    ? stripeConnectData?.stripeConnectMailFrom
                                    : 'info@tortija.de'
                                }
                                mailName={
                                  stripeConnectData?.stripeConnectMailName !== undefined &&
                                  stripeConnectData?.stripeConnectMailName?.length > 0
                                    ? stripeConnectData?.stripeConnectMailName
                                    : ''
                                }
                                mailText={
                                  stripeConnectData?.stripeConnectMailText !== undefined &&
                                  stripeConnectData?.stripeConnectMailText?.length > 0
                                    ? stripeConnectData?.stripeConnectMailText
                                    : ''
                                }
                              />
                            </Elements>
                          )}
                        </>
                      )}
                    </form>
                  </div>
                </div>
              )}

              <div className="m-auto flex flex-col items-center">
                <div className="pb-40 w-full desktop:w-1/3">
                  {tenant && (
                    <div className="text-center text-14 pt-20">
                      {localTenantData?.legal !== undefined ? (
                        <>
                          {localTenantData.customDomain !== undefined &&
                          localTenantData.customDomain === window.location.hostname ? (
                            <>
                              {localTenantData?.legal?.termsOfService && localTenantData?.legal?.noWebsite === false ? (
                                <Link
                                  to={{
                                    pathname:
                                      localTenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                                  }}
                                  target="_blank"
                                  className={styles.link}
                                >
                                  Widerrufsrecht
                                </Link>
                              ) : localTenantData?.legal?.termsOfUseText ? (
                                <>
                                  <div className="cursor-pointer">
                                    <div
                                      className={styles.link}
                                      onClick={() => openLegalInfo('agb')}
                                      aria-hidden="true"
                                    >
                                      Widerrufsrecht
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <Link
                                  to={{
                                    pathname:
                                      localTenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                                  }}
                                  target="_blank"
                                  className={styles.link}
                                >
                                  Widerrufsrecht
                                </Link>
                              )}
                            </>
                          ) : (
                            <>
                              {localTenantData?.legal?.termsOfService && localTenantData?.legal?.noWebsite === false ? (
                                <Link
                                  to={{
                                    pathname:
                                      localTenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                                  }}
                                  target="_blank"
                                  className={styles.link}
                                >
                                  Widerrufsrecht
                                </Link>
                              ) : localTenantData?.legal?.termsOfUseText ? (
                                <>
                                  <div className="cursor-pointer">
                                    <div
                                      className={styles.link}
                                      onClick={() => openLegalInfo('agb')}
                                      aria-hidden="true"
                                    >
                                      Widerrufsrecht
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <Link
                                  to={{
                                    pathname:
                                      localTenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                                  }}
                                  target="_blank"
                                  className={styles.link}
                                >
                                  Widerrufsrecht
                                </Link>
                              )}
                            </>
                          )}
                        </>
                      ) : window.location.hostname === 'business.tortija.de' ? (
                        <Link
                          to={{ pathname: 'https://whitelabel.tortija.de/agb/' }}
                          target="_blank"
                          className={styles.link}
                        >
                          Widerrufsrecht
                        </Link>
                      ) : (
                        <Link to={{ pathname: 'https://www.tortija.de/agb/' }} target="_blank" className={styles.link}>
                          Widerrufsrecht
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="m-auto flex flex-col items-center">
                <div className="pt-0 flex flex-wrap gap-15 desktop:space-x-30">
                  <div>
                    {localTenantData?.legal !== undefined ? (
                      <>
                        {localTenantData.customDomain !== undefined &&
                        localTenantData.customDomain === window.location.hostname ? (
                          <>
                            {localTenantData?.legal?.imprint && localTenantData?.legal?.noWebsite === false ? (
                              <Link
                                to={{
                                  pathname:
                                    localTenantData?.legal?.imprint ?? 'https://whitelabel.tortija.de/impressum/',
                                }}
                                target="_blank"
                                className={styles.link}
                              >
                                Impressum
                              </Link>
                            ) : localTenantData?.legal?.imprintText ? (
                              <>
                                <div className="cursor-pointer">
                                  <div
                                    className={styles.link}
                                    onClick={() => openLegalInfo('imprint')}
                                    aria-hidden="true"
                                  >
                                    Impressum
                                  </div>
                                </div>
                              </>
                            ) : (
                              <Link
                                to={{
                                  pathname:
                                    localTenantData?.legal?.imprint ?? 'https://whitelabel.tortija.de/impressum/',
                                }}
                                target="_blank"
                                className={styles.link}
                              >
                                Impressum
                              </Link>
                            )}
                          </>
                        ) : (
                          <>
                            {localTenantData?.legal?.imprint && localTenantData?.legal?.noWebsite === false ? (
                              <Link
                                to={{
                                  pathname:
                                    localTenantData?.legal?.imprint ?? 'https://whitelabel.tortija.de/impressum/',
                                }}
                                target="_blank"
                                className={styles.link}
                              >
                                Impressum
                              </Link>
                            ) : localTenantData?.legal?.imprintText ? (
                              <>
                                <div className="cursor-pointer">
                                  <div
                                    className={styles.link}
                                    onClick={() => openLegalInfo('imprint')}
                                    aria-hidden="true"
                                  >
                                    Impressum
                                  </div>
                                </div>
                              </>
                            ) : (
                              <Link
                                to={{
                                  pathname:
                                    localTenantData?.legal?.imprint ?? 'https://whitelabel.tortija.de/impressum/',
                                }}
                                target="_blank"
                                className={styles.link}
                              >
                                Impressum
                              </Link>
                            )}
                          </>
                        )}
                      </>
                    ) : window.location.hostname === 'business.tortija.de' ? (
                      <Link
                        to={{ pathname: 'https://whitelabel.tortija.de/impressum/' }}
                        target="_blank"
                        className={styles.link}
                      >
                        Impressum
                      </Link>
                    ) : (
                      <Link
                        to={{ pathname: 'https://www.tortija.de/impressum/' }}
                        target="_blank"
                        className={styles.link}
                      >
                        Impressum
                      </Link>
                    )}
                  </div>
                  <div>
                    {localTenantData?.legal !== undefined ? (
                      <>
                        {localTenantData.customDomain !== undefined &&
                        localTenantData.customDomain === window.location.hostname ? (
                          <>
                            {localTenantData?.legal?.privacyPolicy && localTenantData?.legal?.noWebsite === false ? (
                              <Link
                                to={{
                                  pathname:
                                    localTenantData?.legal?.privacyPolicy ??
                                    'https://whitelabel.tortija.de/datenschutz/',
                                }}
                                target="_blank"
                                className={styles.link}
                              >
                                Datenschutz
                              </Link>
                            ) : localTenantData?.legal?.privacyPolicyText ? (
                              <>
                                <div className="cursor-pointer">
                                  <div
                                    className={styles.link}
                                    onClick={() => openLegalInfo('dataPrivacy')}
                                    aria-hidden="true"
                                  >
                                    Datenschutz
                                  </div>
                                </div>
                              </>
                            ) : (
                              <Link
                                to={{
                                  pathname:
                                    localTenantData?.legal?.privacyPolicy ??
                                    'https://whitelabel.tortija.de/datenschutz/',
                                }}
                                target="_blank"
                                className={styles.link}
                              >
                                Datenschutz
                              </Link>
                            )}
                          </>
                        ) : (
                          <>
                            {localTenantData?.legal?.privacyPolicy && localTenantData?.legal?.noWebsite === false ? (
                              <Link
                                to={{
                                  pathname:
                                    localTenantData?.legal?.privacyPolicy ??
                                    'https://whitelabel.tortija.de/datenschutz/',
                                }}
                                target="_blank"
                                className={styles.link}
                              >
                                Datenschutz
                              </Link>
                            ) : localTenantData?.legal?.privacyPolicyText ? (
                              <>
                                <div className="cursor-pointer">
                                  <div
                                    className={styles.link}
                                    onClick={() => openLegalInfo('dataPrivacy')}
                                    aria-hidden="true"
                                  >
                                    Datenschutz
                                  </div>
                                </div>
                              </>
                            ) : (
                              <Link
                                to={{
                                  pathname:
                                    localTenantData?.legal?.privacyPolicy ??
                                    'https://whitelabel.tortija.de/datenschutz/',
                                }}
                                target="_blank"
                                className={styles.link}
                              >
                                Datenschutz
                              </Link>
                            )}
                          </>
                        )}
                      </>
                    ) : window.location.hostname === 'business.tortija.de' ? (
                      <Link
                        to={{ pathname: 'https://whitelabel.tortija.de/datenschutz/' }}
                        target="_blank"
                        className={styles.link}
                      >
                        Datenschutz
                      </Link>
                    ) : (
                      <Link
                        to={{ pathname: 'https://www.tortija.de/datenschutz/' }}
                        target="_blank"
                        className={styles.link}
                      >
                        Datenschutz
                      </Link>
                    )}
                  </div>
                  <div>
                    {localTenantData?.legal !== undefined ? (
                      <>
                        {localTenantData.customDomain !== undefined &&
                        localTenantData.customDomain === window.location.hostname ? (
                          <>
                            {localTenantData?.legal?.termsOfService && localTenantData?.legal?.noWebsite === false ? (
                              <Link
                                to={{
                                  pathname:
                                    localTenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                                }}
                                target="_blank"
                                className={styles.link}
                              >
                                Nutzungsbedingungen
                              </Link>
                            ) : localTenantData?.legal?.termsOfUseText ? (
                              <>
                                <div className="cursor-pointer">
                                  <div className={styles.link} onClick={() => openLegalInfo('agb')} aria-hidden="true">
                                    Nutzungsbedingungen
                                  </div>
                                </div>
                              </>
                            ) : (
                              <Link
                                to={{
                                  pathname:
                                    localTenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                                }}
                                target="_blank"
                                className={styles.link}
                              >
                                Nutzungsbedingungen
                              </Link>
                            )}
                          </>
                        ) : (
                          <>
                            {localTenantData?.legal?.termsOfService && localTenantData?.legal?.noWebsite === false ? (
                              <Link
                                to={{
                                  pathname:
                                    localTenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                                }}
                                target="_blank"
                                className={styles.link}
                              >
                                Nutzungsbedingungen
                              </Link>
                            ) : localTenantData?.legal?.termsOfUseText ? (
                              <>
                                <div className="cursor-pointer">
                                  <div className={styles.link} onClick={() => openLegalInfo('agb')} aria-hidden="true">
                                    Nutzungsbedingungen
                                  </div>
                                </div>
                              </>
                            ) : (
                              <Link
                                to={{
                                  pathname:
                                    localTenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                                }}
                                target="_blank"
                                className={styles.link}
                              >
                                Nutzungsbedingungen
                              </Link>
                            )}
                          </>
                        )}
                      </>
                    ) : window.location.hostname === 'business.tortija.de' ? (
                      <Link
                        to={{ pathname: 'https://whitelabel.tortija.de/agb/' }}
                        target="_blank"
                        className={styles.link}
                      >
                        Nutzungsbedingungen
                      </Link>
                    ) : (
                      <Link to={{ pathname: 'https://www.tortija.de/agb/' }} target="_blank" className={styles.link}>
                        Nutzungsbedingungen
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="h-full w-full md:flex block">
          <div className={styles.loading}>
            <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerBillingRegister;
