import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import firebase from 'services/firebase';
import ButtonBack from 'components/ButtonBack';
import { AuthContext } from 'providers/AuthProvider';
import { defaultTextColor } from 'layouts/Theme';
import Overlay from 'components/Overlay';
import Button from 'components/Button';
import moment from 'moment';
import { toast } from 'react-toast';
import DatePicker from 'react-datepicker';
import { TrashIcon, CalendarIcon } from '@heroicons/react/outline';
import axios from 'axios';
import CoachingDashboardHeader from './components/CoachingDashboardHeader';
import CoachingDashboardTabs from './components/CoachingDashBoardTabs';
import CoachingDashboardAnamnese from './components/CoachingDashBoardAnamnese';
import CoachingDashboardActivities from './components/CoachingDashBoardActivities';
import CoachingDashboardCalories from './components/CoachingDashboardCalories';
import CoachingDashboardBodyData from './components/CoachingDashboardBodyData';
import CoachingDashboardPlan from './components/CoachingDashboardPlan';
import CoachingDashboardWater from './components/CoachingDashboardWater';
import styles from './styles.module.scss';

type ParamsType = {
  userId: string;
};

export default function CompanyMemberDetail() {
  const { t } = useTranslation();
  const history = useHistory();
  const { userId } = useParams<ParamsType>();
  const [currentUserData, setCurrentUserData] = useState<any>({});
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [userDataValid, setUserDataValid] = useState(false);
  const [profileMailPending, setProfileMailPending] = useState(false);
  const [userDataShareProfile, setUserDataShareProfile] = useState(false);
  const [isActiveString, setIsActiveString] = useState('anamnese');
  const [deleteProfilePopupClass, setDeleteProfilePopupClass] = useState('hidden');
  const [deleteByDateEditClass, setDeleteByDateEditClass] = useState('hidden');
  const [selectedOption, setSelectedOption] = useState('direct');
  const [startDate, setStartDate] = useState(null);
  const [isPendingDeleteByDate, setIsPendingDeleteByDate] = useState(false);
  const [isPendingDelete, setIsPendingDelete] = useState(false);
  const { tenant, theme, tenantData } = useContext(AuthContext);

  const onChange = (dates: any) => {
    setStartDate(dates);
  };

  const handleSendShareMail = async () => {
    setProfileMailPending(true);
    const mailText = `Hallo ${currentUserData?.fullName},\n\ndas Unternehmen Test Trial GmbH hat eine Anfrage gestellt, um Zugriff auf dein Profil zu erhalten.\n\nWenn du die Freigabe erteilen möchtest, gehe bitte wie folgt vor:\n\n1. Öffne dein Profil.\n2. Klicke auf Profil bearbeiten.\n3. Wähle im Abschnitt 'Freigabe deines Profils' die Option 'Profil freigeben'.\n\nDanke für deine Unterstützung!\n\nHerzliche Grüße,\nDein Team von ${tenantData?.company?.companyName}`;

    // Send delete mail to us
    try {
      await axios({
        url: `${process.env.REACT_APP_API_URL}/sendTextMail`,
        method: 'post',
        data: {
          mailSubject: `${tenantData?.company?.companyName} - Anfrage Profilfreigabe`,
          mailFrom:
            tenantData?.settings?.invitationMail?.mailFrom !== undefined &&
            tenantData?.settings?.invitationMail?.mailFrom?.length > 0
              ? tenantData?.settings?.invitationMail?.mailFrom
              : 'info@tortija.de',
          mailName:
            tenantData?.settings?.invitationMail?.mailName !== undefined &&
            tenantData?.settings?.invitationMail?.mailName?.length > 0
              ? tenantData?.settings?.invitationMail?.mailName
              : '',
          mailTo: currentUserData.email,
          mailText,
        },
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(
        response => {
          console.log(response);
          setProfileMailPending(false);
          toast.success(
            'Die Mail zur Beantragung der Profilfreischaltung wurde erfolgreich an das Mitglied geschickt!'
          );
        },
        error => {
          setProfileMailPending(false);
          console.log(error);
          toast.error('Es ist leider etwas schief gelaufen! Bitte versuche es erneut!');
        }
      );
    } catch (error) {
      setProfileMailPending(false);
      console.log(error);
      toast.error('Es ist leider etwas schief gelaufen! Bitte versuche es erneut!');
    }
  };

  const deleteUserAccount = async () => {
    setIsPendingDelete(true);
    if (currentUserData?.type === 'invite') {
      await firebase.firestore().collection('user-invitations').doc(userId).delete();
    } else {
      await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update({ toDelete: true });
    }

    setDeleteProfilePopupClass('hidden');
    toast.success('Nutzer wurde erfolgreich gelöscht!');
    setIsPendingDelete(false);
    history.push('/member');
  };

  const deleteUserByDate = async () => {
    setIsPendingDeleteByDate(true);

    if (startDate === null) {
      toast.error('Bitte wählen Sie ein Datum aus!');
      setIsPendingDeleteByDate(false);
    } else {
      const thisSelectedUser = currentUserData;
      const date = moment(startDate, 'DD.MM.YYYY');
      const unixTimestamp = date.startOf('day').unix();

      try {
        if (currentUserData?.type === 'invite') {
          await firebase.firestore().collection('tenantUserByDateDelete').doc(userId).set({
            uid: userId,
            type: 'invite',
            tenant: thisSelectedUser.tenant,
            nextDate: unixTimestamp,
          });

          await firebase.firestore().collection('user-invitations').doc(userId).update({ deleteDate: unixTimestamp });
        } else {
          await firebase.firestore().collection('tenantUserByDateDelete').doc(userId).set({
            uid: userId,
            type: 'active',
            tenant,
            nextDate: unixTimestamp,
          });

          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(userId)
            .update({ deleteDate: unixTimestamp });

          toast.success('Die Löschung des Mitglieds wurde vorgemerkt!');
        }
      } catch (error) {
        console.log(error);
        setIsPendingDeleteByDate(false);
        toast.error('Es ist leider etwas schief gelaufen! Bitte versuche es erneut!');
      }

      setIsPendingDeleteByDate(false);
      setDeleteProfilePopupClass('hidden');
    }
  };

  const handleResetDeleteByDateQuery = async () => {
    setIsPendingDeleteByDate(true);
    if (currentUserData?.type !== undefined && currentUserData?.type === 'invite') {
      await firebase
        .firestore()
        .collection('user-invitations')
        .doc(userId)
        .update({ deleteDate: firebase.firestore.FieldValue.delete() });
    } else {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(userId)
        .update({ deleteDate: firebase.firestore.FieldValue.delete() });
    }

    await firebase.firestore().collection('tenantUserByDateDelete').doc(userId).delete();

    setIsPendingDeleteByDate(false);
    setDeleteByDateEditClass('hidden');
  };

  const handleChangeDeleteByDateQuery = async () => {
    setIsPendingDeleteByDate(true);

    if (startDate === null) {
      toast.error('Bitte wählen Sie ein Datum aus!');
      setIsPendingDeleteByDate(false);
    } else {
      const date = moment(startDate, 'DD.MM.YYYY');
      const unixTimestamp = date.startOf('day').unix();

      try {
        if (currentUserData?.type !== undefined && currentUserData?.type === 'invite') {
          await firebase.firestore().collection('user-invitations').doc(userId).update({ deleteDate: unixTimestamp });
        } else {
          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(userId)
            .update({ deleteDate: unixTimestamp });
        }

        await firebase.firestore().collection('tenantUserByDateDelete').doc(userId).update({ nextDate: unixTimestamp });

        setIsPendingDeleteByDate(false);
        setDeleteByDateEditClass('hidden');
        toast.success('Lösch Datum erfolgreich geändert!');
      } catch (error) {
        console.log(error);
        setIsPendingDeleteByDate(false);
        toast.error('Es ist leider etwas schief gelaufen! Bitte versuche es erneut!');
      }
    }
  };

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(userId)
      .onSnapshot(
        snapshot => {
          const localUserData = snapshot.data();

          setCurrentUserData(localUserData);

          if (
            localUserData &&
            localUserData.fullName !== undefined &&
            tenantData?.features?.coachingDashboard !== undefined &&
            tenantData?.features?.coachingDashboard === true
          ) {
            setUserDataValid(true);
          }

          if (
            localUserData &&
            localUserData.shareProfileType !== undefined &&
            localUserData.shareProfileType === 'share'
          ) {
            setUserDataShareProfile(true);
          }

          setUserDataLoaded(true);
        },
        error => {
          console.error('Error getting user data: ', error);
        }
      );
    return unsubscribe;
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line

  const CustomDateInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <div className="flex w-full">
          <div className="flex-1">
            <input {...props} />
          </div>
          <div className="ml-10 my-auto">
            <CalendarIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
          </div>
        </div>
      </label>
    );
  };

  return (
    <>
      <Overlay
        setOverlayClass={setDeleteProfilePopupClass}
        overlayClass={deleteProfilePopupClass}
        headline="Mitglied löschen"
        icon={<TrashIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        {currentUserData?.membership?.paymentMethod === 'stripeConnect' &&
        currentUserData?.membership?.type !== 'free' ? (
          <div className="pt-30 font-extralight text-base">
            <div>
              Dieses Mitglied wurde über Tortija Billing registriert und hat noch ein aktives Abonnement oder ein
              Abonnement, welches noch nicht abgelaufen ist.
            </div>
            <div className="pt-10">
              Bitte kündigen Sie als erstes das Abonnement über Stripe. Das Mitglied wird bei gekündigten Abonnement
              automatisch nach Ablauf seines Abonnement-Zeitraums gelöscht.
            </div>
            <div className="pt-30 flex gap-30 pb-40">
              <Link
                to={{
                  pathname: `https://dashboard.stripe.com/${
                    tenantData?.stripeConnect?.stripeConnectAccountId ?? ''
                  }/test/subscriptions/${currentUserData?.membership?.id}`,
                }}
                target="_blank"
                type="button"
                className="my-auto"
              >
                <Button>Zum Abo in Stripe springen</Button>
              </Link>
            </div>
          </div>
        ) : (
          <div>
            <div className="pt-30 font-extralight text-base">
              <div>Wollen Sie das ausgewählte Mitglied wirklich löschen?</div>
              <div>Alle gespeicherten Daten werden dabei gelöscht!</div>
              <div className="font-bold pt-15">Wann soll das Mitglied gelöscht werden?</div>
              <div
                className={`rounded-md bg-${
                  selectedOption === 'direct' ? 'accentColor' : 'lightGray'
                } p-10 mt-10 cursor-pointer`}
                onClick={() => setSelectedOption('direct')}
                aria-hidden="true"
              >
                <div className="flex justify-between">
                  <div>
                    <div
                      className={
                        selectedOption === 'date'
                          ? 'font-semibold text-textColor'
                          : 'font-semibold text-buttonTextColor'
                      }
                    >
                      Sofort
                    </div>
                    <div
                      className={
                        selectedOption === 'date'
                          ? 'font-extralight text-14 text-textColor'
                          : 'font-extralight text-14 text-buttonTextColor'
                      }
                    >
                      Das Mitglied wird sofort gelöscht!
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`rounded-md bg-${
                  selectedOption === 'date' ? 'accentColor' : 'lightGray'
                } p-10 mt-15 cursor-pointer`}
                onClick={() => setSelectedOption('date')}
                aria-hidden="true"
              >
                <div className="flex justify-between">
                  <div>
                    <div
                      className={
                        selectedOption === 'date'
                          ? 'font-semibold text-buttonTextColor'
                          : 'font-semibold text-textColor'
                      }
                    >
                      Zu einem bestimmten Datum
                    </div>
                    <div
                      className={
                        selectedOption === 'date'
                          ? 'font-extralight text-14 text-buttonTextColor'
                          : 'font-extralight text-14 text-textColor'
                      }
                    >
                      Das Mitglied wird zu einem bestimmten Datum gelöscht!
                    </div>
                  </div>
                </div>
              </div>

              {selectedOption === 'date' && (
                <div>
                  <div className="pt-10 font-extralight">
                    Bitte wählen Sie ein Datum aus, wann das Mitglied gelöscht werden soll.
                  </div>
                  <div>Einmal pro Tag wird geprüft ob Mitglieder gelöscht werden müssen.</div>
                  <div className="pt-10">
                    <DatePicker
                      className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                      selected={startDate}
                      onChange={onChange}
                      startDate={startDate}
                      locale="de"
                      dateFormat="dd.MM.yyyy"
                      minDate={moment().toDate()}
                      withPortal
                      customInput={React.createElement(React.forwardRef(CustomDateInput))}
                      calendarStartDay={1}
                      onFocus={e => e.target.blur()}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="pt-30 flex gap-30 pb-40">
              <div>
                {selectedOption === 'direct' ? (
                  <Button
                    disabled={isPendingDelete}
                    isPending={isPendingDelete}
                    className="w-full"
                    onClick={() => {
                      deleteUserAccount();
                    }}
                  >
                    Mitglied löschen
                  </Button>
                ) : (
                  <Button
                    isPending={isPendingDeleteByDate}
                    disabled={isPendingDeleteByDate}
                    className="w-full"
                    onClick={() => {
                      deleteUserByDate();
                    }}
                  >
                    Löschung planen
                  </Button>
                )}
              </div>
              <div>
                <Button
                  className="w-full"
                  buttonStyle="white"
                  onClick={() => {
                    setDeleteProfilePopupClass('hidden');
                  }}
                >
                  Abbrechen
                </Button>
              </div>
            </div>
          </div>
        )}
      </Overlay>

      <Overlay
        setOverlayClass={setDeleteByDateEditClass}
        overlayClass={deleteByDateEditClass}
        headline="Geplante Löschung anpassen"
        icon={<TrashIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            Name des Mitglieds: <span className="font-bold">{currentUserData?.fullName}</span>
          </div>
          <div className="pt-5 font-extralight text-base">
            Lösch Datum:{' '}
            <span className="font-bold">
              {currentUserData?.deleteDate !== undefined
                ? moment.unix(currentUserData?.deleteDate).format('DD.MM.YYYY')
                : ''}
            </span>
          </div>
          <div className="pt-20 font-extralight">
            Wenn Sie das Lösch Datum ändern wollen, geben Sie hier bitte ein neues Datum ein:
          </div>
          <div className="pt-10">
            <DatePicker
              className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              locale="de"
              dateFormat="dd.MM.yyyy"
              minDate={moment().toDate()}
              withPortal
              customInput={React.createElement(React.forwardRef(CustomDateInput))}
              calendarStartDay={1}
              onFocus={e => e.target.blur()}
            />
          </div>
          <div className="pt-20 font-extralight">
            Wenn Sie den Löschvorgang rückgängig machen wollen und das Mitglied nicht mehr löschen wollen, klicken Sie
            bitte auf den Button "Löschvorgang rückgängig"!
          </div>
          <div className="pt-30 flex gap-30 pb-40">
            <div>
              <Button
                className="w-full"
                isPending={isPendingDeleteByDate}
                disabled={isPendingDeleteByDate}
                onClick={() => {
                  handleChangeDeleteByDateQuery();
                }}
              >
                Datum ändern
              </Button>
            </div>

            <div>
              <Button
                className="w-full"
                isPending={isPendingDeleteByDate}
                disabled={isPendingDeleteByDate}
                onClick={() => {
                  handleResetDeleteByDateQuery();
                }}
              >
                Löschvorgang rückgängig
              </Button>
            </div>
          </div>
        </div>
      </Overlay>

      <div className={styles.wrapper}>
        {userDataLoaded ? (
          <div>
            <div className="flex">
              <Link
                to={{
                  pathname: '/member',
                }}
              >
                {' '}
                <ButtonBack
                  text="Zurück"
                  className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                />
              </Link>
            </div>
            {userDataShareProfile === false ? (
              <div className="mt-30">
                <div className="bg-lightGray rounded-3xl py-100 px-20 mx-auto text-center font-bold text-25">
                  <div>Profil noch nicht freigegeben!</div>
                  <div className="font-extralight px-40 pt-20">Der Nutzer hat sein Profil noch nicht freigegeben.</div>
                  <div className="font-extralight px-40 pt-15">
                    Mit einem Klick auf den folgenden Button können Sie eine Freigabe-Anfrage senden.
                  </div>
                  <div className="font-extralight px-40 pt-5">
                    Der Nutzer erhält dann eine automatisierte E-Mail mit der Bitte, sein Profil freizuschalten.
                  </div>
                  <div className="mx-40 mt-40">
                    <Button
                      className="mx-auto w-full"
                      onClick={() => handleSendShareMail()}
                      isPending={profileMailPending}
                      disabled={profileMailPending}
                    >
                      Freigabe anfordern
                    </Button>
                  </div>
                </div>
              </div>
            ) : userDataValid ? (
              <div>
                <CoachingDashboardHeader
                  userData={currentUserData}
                  deleteUserFunction={setDeleteProfilePopupClass}
                  editDeleteDateFunction={setDeleteByDateEditClass}
                  userId={userId}
                />
                <CoachingDashboardTabs isActive={isActiveString} setIsActive={setIsActiveString} />

                <div>
                  {isActiveString === 'anamnese' ? (
                    <CoachingDashboardAnamnese userData={currentUserData} userId={userId} />
                  ) : isActiveString === 'activities' ? (
                    <CoachingDashboardActivities userData={currentUserData} />
                  ) : isActiveString === 'calories' ? (
                    <CoachingDashboardCalories userData={currentUserData} />
                  ) : isActiveString === 'plan' ? (
                    <CoachingDashboardPlan userData={currentUserData} />
                  ) : isActiveString === 'water' ? (
                    <CoachingDashboardWater userData={currentUserData} />
                  ) : isActiveString === 'bodyData' ? (
                    <CoachingDashboardBodyData userData={currentUserData} />
                  ) : (
                    <CoachingDashboardAnamnese userData={currentUserData} userId={userId} />
                  )}
                </div>
              </div>
            ) : (
              <div>Leider konnten wir die Nutzerdaten nicht laden!</div>
            )}
          </div>
        ) : (
          <div className="h-full w-full md:flex block">
            <div className={styles.loading}>
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
